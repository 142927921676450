import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import styles from "./DevOcean.module.scss";
import { useState } from "react";
import { SoSInputTitle } from "../../components/common/soSInputTitle/SoSInputTitle";
import { useQSoS_UserByEmail } from "../../hooks/queries/user/useQSoS_UserByEmail";
import { useQ_Users } from "../../hooks/queries/user/useQ_Users";
import { SoSCopyToClipboard } from "../../components/common/SoSCopyToClipboard/SoSCopyToClipboard";
import { useQOffers_GetTermTemplates } from "../../hooks/offers/queries/termTemplate/useQOffers_GetTermTemplates";
import { useQOffers_GetLeads } from "../../hooks/offers/queries/leads/useQOffers_GetLeads";
import { useToastContext } from "../../contexts/ToastContext";
import { SoSConfirmWrapper } from "../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SoSSwich } from "../../components/common/SoSSwich/SoSSwich";
import { SoSToolbox } from "../../components/SoSToolBox/SoSToolBox";

export const DevOcean = () => {
   const { toatsState, toastDispatch } = useToastContext();
   // const userRes = useQSoS_UserByEmail({ email: "ocean.nguyen@simplyhomes.com" });
   // // console.log(userRes.data?.user);

   // const termTemplatesRes = useQOffers_GetTermTemplates();
   // // console.log(termTemplatesRes.data?.templates);

   // const usersRes = useQ_Users();

   // // console.log(usersRes.data?.users);

   // const leadsRes = useQOffers_GetLeads();
   // // console.log(leadsRes.data?.leads);

   const [isPopupOpen, setIsPopupOpen] = useState(false);

   const addErrorToast = () => {
      toastDispatch({
         type: "ADD_TOAST",
         payload: {
            type: "error",
            onAction: () => alert("Action clicked!"),
            message: "toast " + toatsState.toasts.length,
         },
      });
   };

   const addSuccessToast = () => {
      toastDispatch({
         type: "ADD_TOAST",
         payload: {
            type: "success",
            onAction: () => alert("Action clicked!"),
            message: "toast " + toatsState.toasts.length,
         },
      });
   };
   const [isChecked, setIsChecked] = useState(false);

   const items = [
      {
         icon: "export.svg",
         onClick: () => alert("Tool 1 clicked"),
      },
      {
         title: "Helps",
         icon: "info.svg",
         onClick: () => alert("Tool 2 clicked"),
      },
      {
         title: "Services",
         icon: "notes.svg",
         onClick: () => alert("Tool 3 clicked"),
      },
      {
         title: "About Company",
         icon: "refresh.svg",
         onClick: () => alert("Tool 3 clicked"),
      },
      {
         title: "Phone & Email",
         icon: "save.svg",
         onClick: () => alert("Tool 3 clicked"),
      },
   ];

   return (
      <div className={`${styles.contentC}`}>
         {/* <SoSToolbox items={items} /> */}
         {/* <div>
            <SoSSwich checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
         </div>
         <SHrSosButton type="bland">Review Property</SHrSosButton>
         <SHrSosButton type="dangerous" buttonProps={{ onClick: () => addErrorToast() }}>
            Review Property
         </SHrSosButton>
         <SHrSosButton type="elevated" buttonProps={{ onClick: () => addSuccessToast() }}>
            Review Property
         </SHrSosButton>
         <SHrSosButton type="filled">Review Property</SHrSosButton>
         <SHrSosButton type="outlined">Review Property</SHrSosButton>

         <SoSConfirmWrapper type="default" onCancel={() => {}} onConfirm={() => {}}>
            <SHrSosButton type="positive">Review Property</SHrSosButton>
         </SoSConfirmWrapper>

         <SHrSosButton type="text">Review Property</SHrSosButton>
         <SHrSosButton type="tonal" buttonProps={{ onClick: () => setIsPopupOpen(!isPopupOpen) }}>
            Review Property
         </SHrSosButton>
         <SoSInputTitle title="Question ID" type="text" value={""} handleValue={() => {}} /> */}
         {/* <SHrPopup show={isPopupOpen}>
            <div>Popup Content</div>
            <SHrSosButton type="filled" buttonProps={{ onClick: () => setIsPopupOpen(!isPopupOpen) }}>
               Close
            </SHrSosButton>
         </SHrPopup> */}

         {/* <SHrPopup show={isPopupOpen}>
            <PViNewRentTypeModal onClose={() => setIsPopupOpen(!isPopupOpen)} />
         </SHrPopup> */}
         {/* <SoSCopyToClipboard value="ppp" /> */}
         {/* <div style={{ display: "flex" }}>
            <OffersPropertyCard property={1} />
            <OffersPropertyCard property={1} />
         </div> */}
      </div>
   );
};
