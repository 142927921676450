import styles from "./HomePage.module.scss";
const HomePage = () => {
   return (
      <article className={`${styles.contentC}`}>
         <b>Please go to available apps on the left vertical nav bar</b>
      </article>
   );
};

export default HomePage;
