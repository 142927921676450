import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useQSoS_Notifications } from "../queries/useQSoS_Notifications";

export const useMSoS_ChangeNotificationReadStatus = () => {
   const { sosAxios } = useSosAxios();

   const qNotifications = useQSoS_Notifications();

   return useMutation({
      mutationFn: ({ nid, read }: TChangeNotificationReadStatusParams) =>
         sosAxios
            .patch(`/notifications/${nid}/read`, { read })
            .then<TNofs_ChangeNotificationReadStatus_Response>(({ data }) => {
               qNotifications.refetch();
               return data;
            }),
   });
};

export type TChangeNotificationReadStatusParams = {
   nid: number;
   read: boolean;
};
export type TNofs_ChangeNotificationReadStatus_Response = {
   message: string;
};
