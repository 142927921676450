import classNames from "classnames/bind";
import { SHu_js_currencyFormat } from "@simplyhomes/utils";
import styles from "./PViSourcePropertyCard.module.scss";
import { CF_CDN_URL } from "../../../../../../utils/CF_CDN_URL";
import { useQPVi_PropertiesBySource } from "../../../../../../hooks/property-viability/queries/useQPVi_PropertiesBySource";

const cx = classNames.bind(styles);

export const PViSourcePropertyCard = ({ pid, sid }: TPViSourcePropertyCardProps) => {
   const qPVi_PropertiesBySource = useQPVi_PropertiesBySource({ sid });

   if (!qPVi_PropertiesBySource.data) return;

   const property = qPVi_PropertiesBySource.data.properties.find((p) => p.pid === pid);

   if (!property) return null;

   const { units = [], street_view_url_150, asking_price, address_one_line, sqft } = property;
   const totalBeds = units?.map((u) => u.beds).reduce((acc, value) => acc + value, 0);
   const totalBaths = units?.map((u) => u.baths).reduce((acc, value) => acc + value, 0);

   return (
      <div className={cx("container")}>
         <div className={cx("property-img")}>
            <img src={street_view_url_150} alt="" />
         </div>
         <div className={cx("contentC")}>
            <div className={cx("addressC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/offer/location_on_black.svg")} alt="" />
               </div>
               <span>{address_one_line}</span>
            </div>
            <div className={cx("numbersC")}>
               <div className={cx("price")}>
                  <span>Asking Price</span>
                  <span className={cx("number-price")}>{SHu_js_currencyFormat(asking_price)}</span>
               </div>
               <div className={cx("price")}>
                  <span>Current Max Price</span>
                  <span className={cx("number-price")}>--</span>
               </div>
            </div>

            <div className={cx("inforC")}>
               <div className={cx("unit")}>
                  <div className={cx("icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/square_foot_black.svg")} alt="" />
                  </div>
                  <span>
                     {sqft} ft <sup>2</sup>
                  </span>
               </div>
               <div className={cx("unit")}>
                  <div className={cx("icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/house_siding_black.svg")} alt="" />
                  </div>
                  <span>{units?.length} unit</span>
               </div>
               <div className={cx("unit")}>
                  <div className={cx("icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/bedroom_parent_black.svg")} alt="" />
                  </div>
                  <span>
                     {totalBeds}
                     {`${totalBeds > 1 ? " beds" : " bed"}`}{" "}
                  </span>
               </div>
               <div className={cx("unit")}>
                  <div className={cx("icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/bathroom.svg")} alt="" />
                  </div>
                  <span>
                     {" "}
                     {totalBaths}
                     {`${totalBaths > 1 ? " baths" : " bath"}`}{" "}
                  </span>
               </div>
            </div>
         </div>
      </div>
   );
};

type TPViSourcePropertyCardProps = {
   pid: string;
   sid: number;
};
