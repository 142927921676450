import classNames from "classnames/bind";
import styles from "./ToastContainer.module.scss";
import { useToastContext } from "../../contexts/ToastContext";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { SHrSosButton } from "@simplyhomes/react";
import { useEffect, useState } from "react";

const cx = classNames.bind(styles);
export const ToastContainer = () => {
   const { toatsState } = useToastContext();

   return (
      <div className={cx("toast-container")}>
         {toatsState.toasts.map((toast, i) => (
            <Toast toast={toast} index={i} key={i} />
         ))}
      </div>
   );
};

type ToastProps = {
   toast: TToast;
   index: number;
};

export type TToast = {
   type: "success" | "error" | "informative" | "warning";
   message?: string;
   onAction?: () => void;
};

const getToastImage = (type: string) => {
   switch (type) {
      case "error":
         return "typeError.svg";
      case "informative":
         return "typeInformative.svg";
      case "success":
         return "typeSucess.svg";
      case "warning":
         return "typeWarning.svg";
      default:
         return "";
   }
};

const getTitleAndMessage = (type: string) => {
   switch (type) {
      case "error":
         return {
            title: "Error!",
            message: "Error Message",
         };
      case "informative":
         return {
            title: "Info!",
            message: "Informative Message",
         };
      case "success":
         return {
            title: "Success!",
            message: "Success Message",
         };
      case "warning":
         return {
            title: "Warning!",
            message: "Warning Message",
         };
      default:
         return {
            title: "",
            message: "",
         };
   }
};

const Toast = ({ toast, index }: ToastProps) => {
   const { toastDispatch } = useToastContext();
   const [exiting, setExiting] = useState(false);

   useEffect(() => {
      let smallTimer: any;
      const timer = setTimeout(() => {
         setExiting(true);
         toastDispatch({ type: "REMOVE_TOAST", payload: { index } });
      }, 4500);
      return () => {
         clearTimeout(timer);
         clearTimeout(smallTimer);
      };
   }, [index, toastDispatch]);

   const handleRemoveToast = () => {
      setExiting(true);
      setTimeout(() => {
         toastDispatch({ type: "REMOVE_TOAST", payload: { index } });
      }, 500);
   };

   return (
      <div key={index} className={cx("toast", `toast-${toast.type}`, { "toast-exit": exiting })}>
         <div className={cx("img-icon")}>
            <img src={CF_CDN_URL(`/assets/toast/${getToastImage(toast.type)}`)} alt="" />
         </div>
         <div className={cx("toast-content")}>
            <span>
               <b>{getTitleAndMessage(toast.type).title}</b>
            </span>
            <span className={cx("toast-message")}>{toast.message || getTitleAndMessage(toast.type).title}</span>
         </div>
         {toast?.onAction && (
            <SHrSosButton type="outlined" buttonProps={{ className: cx("btn-action"), onClick: toast.onAction }}>
               Action
            </SHrSosButton>
         )}

         <SHrSosButton
            type="text"
            buttonProps={{
               className: cx("btn-clear"),
               onClick: () => handleRemoveToast(),
            }}
         >
            <div className={cx("img-icon-clear")}>
               <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
            </div>
         </SHrSosButton>
      </div>
   );
};
