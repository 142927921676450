import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import useDebounce from "../../react/useDebounce";

// Usage:
// const handleDefault = async (o: SoS_GetOptions_Option) => {
//    if (!currentValue) dispatch({ overwrite: { value: o.value } });
// }
// const options = useSoSOptions_Offers_Simply_Terms({ handleDefault });

export const useQSoS_Options = ({ handleDefault = () => {}, ...params }: TQSoS_Options_Props) => {
   const { sosAxios } = useSosAxios();
   const query = useQuery<SoS_GetOptions_Response>({
      queryKey: [`useQSoS_Options`, params],
      queryFn: () =>
         sosAxios.get(`/options`, { params }).then(({ data }: { data: SoS_GetOptions_Response }) => {
            const defaultOption = data.options.find((o) => o.is_default);
            if (defaultOption) handleDefault(defaultOption);
            return data;
         }),
   });

   return query;
};

type TQSoS_Options_Props = {
   moid: string;
   housing_authority_id?: string | number;
   q?: string;
   handleDefault?: (o: SoS_GetOptions_Option) => void;
};

export type SoS_GetOptions_Option = { value: string; label: string; is_default?: boolean };

export type SoS_GetOptions_Response = { options: SoS_GetOptions_Option[] };

type WrapperParams = Omit<TQSoS_Options_Props, "moid">;

export const useSoSOptions_PVi_Source_Reject_Reasons = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_property_viability_rejection_reason" });

export const useSoSOptions_Offers_Simply_Terms = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_simply_term" });

export const useSoSOptions_Offers_Leads_Statuses = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_offer_lead_status" });

export const useSoSOptions_Offers_OfferStatuses = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_offer_offer_status" });

export const useSoSOptions_Offers_OfferTypes = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_offer_offer_type" });

export const useSoSOptions_Properties_PropertyTypes = (
   p?: WrapperParams & { housing_authority_id: string | number | undefined }
) => useQSoS_Options({ ...p, moid: "#properties.property_type" });

export const useSoSOptions_Sources_Source = (p?: WrapperParams) => useQSoS_Options({ ...p, moid: "#sources.source" });

export const useSoSOptions_Convo_Thread_Tags = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_conversation_thread_tag" });

export const useSoSOptions_Offers_Lead_Next_Actions = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_offer_lead_next_action" });

export const useSoSOptions_PVi_Source_Source = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_property_viability_source_source" });

export const useSoSOptions_PVi_PropertyAddress = (p?: WrapperParams) => {
   const debouncedParams = {
      ...p,
      q: useDebounce(p?.q, 1000),
   };

   return useQSoS_Options({ ...debouncedParams, moid: "#address.autocomplete" });
};

export const useSoSOptions_PVi_HousingAuthorities = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "#property.housing_authorities" });

export const useSoSOptions_PVi_AccessDetailsObjectType = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_property_viability_access_detail" });

export const useSoSOptions_PVi_WaterSupply = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_property_viability_water_supply" });

export const useSoSOptions_PVi_CurrentLeaseType = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_property_viability_current_lease_type" });

export const useSoSOptions_PVi_ElectricalType = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_property_viability_electrical_type" });

export const useSoSOptions_PVi_RoofType = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_property_viability_roof_type" });

export const useSoSOptions_PVi_Siding = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_property_viability_siding" });

export const useSoSOptions_PVi_Foundation = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_property_viability_foundation" });

export const useSoSOptions_PVi_Construction = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_property_viability_construction" });

export const useSoSOptions_PVi_SewageType = (p?: WrapperParams) =>
   useQSoS_Options({ ...p, moid: "sos_property_viability_sewage_type" });
