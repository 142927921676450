import classNames from "classnames/bind";
import styles from "./PViAddNewPropertyModal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import { SoSInputSelect } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { SoSInputTitle } from "../../../components/common/soSInputTitle/SoSInputTitle";
import {
   useSoSOptions_PVi_PropertyAddress,
   useSoSOptions_PVi_Source_Source,
} from "../../../hooks/queries/options/useQSoS_Options";
import { useState } from "react";
import { OffersCreateNewContactModal } from "../../OffersPage/OffersCreateNewContactModal/OffersCreateNewContactModal";
import { useToastContext } from "../../../contexts/ToastContext";
import {
   CreateSourceParams,
   CreateSourceParams_Property,
   useMPVi_Source_CreateSource,
} from "../../../hooks/property-viability/mutates/useMPVi_Source_CreateSource";
import { useQSOS_Contacts } from "../../../hooks/contact/queries/useQSOS_Contacts";
import { database_helpers_createPayloadHelper as dbcb } from "../../../hooks/database/mutations/utils";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";

const cx = classNames.bind(styles);

export const PViAddNewPropertyModal = ({ onClose }: PViAddNewPropertyModalProps) => {
   const { toastDispatch } = useToastContext();

   const qPViSourceSource = useSoSOptions_PVi_Source_Source();

   const qGetContacts = useQSOS_Contacts();

   const { mutate, isPending } = useMPVi_Source_CreateSource({
      onSuccess: () => {
         onClose();
      },
   });

   const contacts = qGetContacts.data?.contacts || [];

   const [q, setQ] = useState("");

   const qPViPropertyAddresses = useSoSOptions_PVi_PropertyAddress({ q });

   const getPropertyAddressesOptions = qPViPropertyAddresses.data?.options || [];

   const propertyAddressesOptions = getPropertyAddressesOptions.map((o) => ({ value: o.value, label: o.label }));

   const getSourceSourceOptions = qPViSourceSource.data?.options || [];

   const sourceSourceOptions = getSourceSourceOptions.map((o) => ({ value: o.value, label: o.label }));

   const [sourceSourceSelected, setSourceSourceSelected] = useState<string>("");

   const [contactModal, setContactModal] = useState<{ isOpen: boolean; mode: "p" | "s" }>({ isOpen: false, mode: "s" });

   const [editingPropertyIndex, setEditingPropertyIndex] = useState<number | null>(null);

   const [selectedSourceContacts, setSelectedSourceContacts] = useState<number[]>([]);

   const [properties, setProperties] = useState<CreateSourceParams_Property[]>([
      {
         address: "",
         price: 0,
         cids: [],
         units: [
            {
               bed: 1,
               bath: 1,
            },
         ],
      },
   ]);

   const handleSelectionContactSource = (cid: number) => {
      setSelectedSourceContacts((prevContacts) => {
         if (!prevContacts.includes(cid)) {
            return [...prevContacts, cid];
         }
         toastDispatch({ type: "ADD_TOAST", payload: { message: "Contact already selected", type: "error" } });
         return prevContacts;
      });
   };

   const handleSelectionContactProperty = (cid: number) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP, index) => {
            if (index === editingPropertyIndex) {
               if (!prevP.cids.includes(cid)) return { ...prevP, cids: [...prevP.cids, cid] };
               toastDispatch({
                  type: "ADD_TOAST",
                  payload: {
                     message: `Contact already selected in property ${editingPropertyIndex + 1}`,
                     type: "error",
                  },
               });
            }
            return prevP;
         });
      });
   };

   const handleRemoveSouceContact = (i: number) => {
      setSelectedSourceContacts((prevContacts) => {
         return prevContacts.filter((_, index) => index !== i);
      });
   };

   const handleRemoveContactProperty = (i: number) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP) => {
            return {
               ...prevP,
               cids: prevP.cids.filter((_, indexU) => indexU !== i),
            };
         });
      });
   };

   const handleAddProperty = () => {
      setProperties((prevProperties) => {
         return [
            ...prevProperties,
            {
               address: "",
               price: 0,
               cids: [],
               units: [
                  {
                     bed: 1,
                     bath: 1,
                  },
               ],
            },
         ];
      });
   };

   const handleRemoveProperty = (i: number) => {
      setProperties((prevProperties) => {
         return prevProperties.filter((_, index) => index !== i);
      });
   };

   const handleRemoveUnit = (j: number) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP) => {
            return {
               ...prevP,
               units: prevP.units.filter((_, indexU) => indexU !== j),
            };
         });
      });
   };

   const handleCreateSource = () => {
      const params: CreateSourceParams = {
         source_source: sourceSourceSelected,
         cids: selectedSourceContacts,
         properties: properties,
      };
      mutate(params);
   };

   const handlePropertyAddressChange = (i: number, address: string) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP, index) => {
            if (index === i) {
               return { ...prevP, address };
            }
            return prevP;
         });
      });
   };

   const handlePropertyPriceChange = (i: number, value: string) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP, index) => {
            if (index === i) {
               return { ...prevP, price: Number(value) };
            }
            return prevP;
         });
      });
   };

   const handleAddUnitToProperty = (i: number) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP, index) => {
            if (index === i) {
               return {
                  ...prevP,
                  units: [
                     ...prevP.units,
                     {
                        bed: 1,
                        bath: 1,
                     },
                  ],
               };
            }
            return prevP;
         });
      });
   };

   const handleBedroomChange = (i: number, j: number, value: string) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP, index) => {
            if (index === i) {
               return {
                  ...prevP,
                  units: prevP.units.map((prevU, indexU) => {
                     if (indexU === j) {
                        return { ...prevU, bed: Number(value) };
                     }
                     return prevU;
                  }),
               };
            }
            return prevP;
         });
      });
   };

   const handleBathroomChange = (i: number, j: number, value: string) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP, index) => {
            if (index === i) {
               return {
                  ...prevP,
                  units: prevP.units.map((prevU, indexU) => {
                     if (indexU === j) {
                        return { ...prevU, bath: Number(value) };
                     }
                     return prevU;
                  }),
               };
            }
            return prevP;
         });
      });
   };

   const handleOpenModalAddContactProperty = (i: number) => {
      setContactModal({ isOpen: true, mode: "p" });
      setEditingPropertyIndex(i);
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/property_viability/home_black.svg")} alt="" />
            </div>
            <span>Create New Properties</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         <div className={cx("contentC")}>
            <div className={cx("sourceType")}>
               <SoSInputSelect
                  title="Source Type"
                  isMulti={false}
                  value={sourceSourceOptions.find((o) => o.label === sourceSourceSelected)?.label || ""}
                  onChange={(o) => setSourceSourceSelected(o.label)}
                  options={sourceSourceOptions}
                  isLoading={false}
               />
            </div>

            <div className={cx("headingAndBtn")}>
               <span>Source Contacts</span>
               <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("btn"),
                     onClick: () => setContactModal({ isOpen: true, mode: "s" }),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_viability/add_black.svg")} alt="" />
                  </div>
                  Add a Contact
               </SHrSosButton>
            </div>

            <div className={cx("sourceContactGroupC")}>
               {!selectedSourceContacts.length ? (
                  <div className={cx("noContact")}>No source's contact found</div>
               ) : (
                  selectedSourceContacts.map((cid, i) => {
                     const contact = contacts.find((c) => c.cid === cid);
                     return (
                        <div className={cx("sourceContactInfoC")} key={i}>
                           <SoSDataPoint
                              type="bold-title"
                              title="Name"
                              value={contact?.name}
                              database={dbcb("sos_contacts", [{ column: "cid", id: cid }], () =>
                                 qGetContacts.refetch()
                              )("name")}
                           />
                           <SoSDataPoint
                              type="bold-title"
                              title="Phone"
                              value={contact?.phone}
                              database={dbcb("sos_contacts", [{ column: "cid", id: cid }], () =>
                                 qGetContacts.refetch()
                              )("phone_primary")}
                           />
                           <SoSDataPoint
                              type="bold-title"
                              title="Email"
                              value={contact?.email}
                              database={dbcb("sos_contacts", [{ column: "cid", id: cid }], () =>
                                 qGetContacts.refetch()
                              )("email")}
                           />
                           <div className={cx("img-icon")} onClick={() => handleRemoveSouceContact(i)}>
                              <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                           </div>
                        </div>
                     );
                  })
               )}
            </div>

            <hr className={cx("divide")} />

            <div className={cx("headingAndBtn")}>
               <span>Property Details</span>
               {/* <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("btn"),
                     onClick: () => handleAddProperty(),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_viability/add_black.svg")} alt="" />
                  </div>
                  Add a Property
               </SHrSosButton> */}
            </div>

            {properties.length ? (
               properties.map((p, i) => (
                  <div className={cx("propertyInforC")} key={i}>
                     <div className={cx("titleAndRemoveBtn")}>
                        <span>Property {i + 1}</span>
                        <div className={cx("img-icon")} onClick={() => handleRemoveProperty(i)}>
                           <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
                        </div>
                     </div>

                     <div className={cx("propertyAddress")}>
                        <SoSInputSelect
                           title="Property Address"
                           isMulti={false}
                           value={p.address}
                           onChange={(o) => handlePropertyAddressChange(i, o.value)}
                           options={propertyAddressesOptions}
                           isLoading={false}
                           onInputChange={(value) => setQ(value)}
                        />
                     </div>

                     <div className={cx("askingPrice")}>
                        <SoSInputTitle
                           title="Asking Price"
                           placeholder="Enter a price"
                           value={p.price}
                           handleValue={(value) => handlePropertyPriceChange(i, value)}
                           type="number"
                        />
                     </div>

                     <div className={cx("propertyContactC")}>
                        <div className={cx("headingAndBtn")}>
                           <span>Contacts</span>
                           <SHrSosButton
                              type="text"
                              buttonProps={{
                                 className: cx("btn"),
                                 onClick: () => handleOpenModalAddContactProperty(i),
                              }}
                           >
                              <div className={cx("img-icon")}>
                                 <img src={CF_CDN_URL("/assets/property_viability/add_black.svg")} alt="" />
                              </div>
                              Add a Contact
                           </SHrSosButton>
                        </div>

                        {p.cids.map((cid, i) => {
                           const contact = contacts.find((c) => c.cid === cid);
                           return (
                              <div className={cx("propertyContactInfoC")} key={i}>
                                 <SoSDataPoint
                                    type="bold-title"
                                    title="Name"
                                    value={contact?.name}
                                    database={dbcb("sos_contacts", [{ column: "cid", id: cid }], () =>
                                       qGetContacts.refetch()
                                    )("name")}
                                 />
                                 <SoSDataPoint
                                    type="bold-title"
                                    title="Phone"
                                    value={contact?.phone}
                                    database={dbcb("sos_contacts", [{ column: "cid", id: cid }], () =>
                                       qGetContacts.refetch()
                                    )("phone_primary")}
                                 />
                                 <SoSDataPoint
                                    type="bold-title"
                                    title="Email"
                                    value={contact?.email}
                                    database={dbcb("sos_contacts", [{ column: "cid", id: cid }], () =>
                                       qGetContacts.refetch()
                                    )("email")}
                                 />
                                 <div className={cx("img-icon")} onClick={() => handleRemoveContactProperty(i)}>
                                    <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                                 </div>
                              </div>
                           );
                        })}
                     </div>

                     <hr className={cx("divide")} />

                     <div className={cx("headingAndBtn")}>
                        <span>Unit Details</span>
                        <SHrSosButton
                           type="text"
                           buttonProps={{
                              className: cx("btn"),
                              onClick: () => handleAddUnitToProperty(i),
                           }}
                        >
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/property_viability/add_black.svg")} alt="" />
                           </div>
                           Add a Unit
                        </SHrSosButton>
                     </div>

                     <div className={cx("unitGroupC")}>
                        {p.units.map((u, j) => (
                           <div className={cx("unitInfoC")} key={j}>
                              <div className={cx("headingAndBtn")}>
                                 <span>Unit {j + 1}</span>
                                 <div className={cx("img-icon")} onClick={() => handleRemoveUnit(j)}>
                                    <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                                 </div>
                              </div>

                              <div className={cx("bedAndBathC")}>
                                 <div className={cx("askingPrice")}>
                                    <SoSInputTitle
                                       title="Bedrooms"
                                       placeholder="Enter number of bedrooms"
                                       inputProps={{
                                          min: 0,
                                       }}
                                       handleValue={(value) => handleBedroomChange(i, j, value)}
                                       value={u.bed}
                                       type={"number"}
                                    />
                                 </div>
                                 <div className={cx("askingPrice")}>
                                    <SoSInputTitle
                                       title="Bathrooms"
                                       placeholder="Enter number of bathrooms"
                                       value={u.bath}
                                       inputProps={{
                                          min: 0,
                                       }}
                                       handleValue={(value) => handleBathroomChange(i, j, value)}
                                       type={"number"}
                                    />
                                 </div>
                              </div>
                           </div>
                        ))}
                     </div>
                  </div>
               ))
            ) : (
               <div className={cx("noProperty")}>No property</div>
            )}
         </div>
         <div className={cx("footerBtn")}>
            <SHrSosButton
               type="text"
               buttonProps={{
                  className: cx("btn"),
                  onClick: () => handleAddProperty(),
               }}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/add_black.svg")} alt="" />
               </div>
               Add a Property
            </SHrSosButton>

            <SoSConfirmWrapper
               title="Are you sure you want to create properties?"
               content="This action allows to create properties."
               type="default"
               onConfirm={() => handleCreateSource()}
            >
               <div className={cx("btnSave")}>
                  <SHrSosButton
                     buttonProps={{
                        className: cx("btn"),
                        disabled: !sourceSourceSelected || properties.some((p) => !p.address),
                     }}
                     loading={isPending}
                  >
                     Save
                  </SHrSosButton>
               </div>
            </SoSConfirmWrapper>

            <SHrPopup show={contactModal.isOpen}>
               <OffersCreateNewContactModal
                  onClose={() => setContactModal({ ...contactModal, isOpen: false })}
                  onSelection={(cid: number) =>
                     contactModal.mode === "p" ? handleSelectionContactProperty(cid) : handleSelectionContactSource(cid)
                  }
               />
            </SHrPopup>
         </div>
      </div>
   );
};

type PViAddNewPropertyModalProps = {
   onClose: () => void;
};
