import { useCallback, useRef } from "react";

export const useDebounceCallback = <T>(callback: (p: T) => void, delay: number = 500) => {
   const timeoutRef = useRef<NodeJS.Timeout | null>(null);

   const debouncedCallback = useCallback(
      (p: T) => {
         if (timeoutRef.current) clearTimeout(timeoutRef.current);
         timeoutRef.current = setTimeout(() => callback(p), delay);
      },
      [callback, delay]
   );

   return { debouncedCallback };
};
