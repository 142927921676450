import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useQSoS_Notifications } from "../queries/useQSoS_Notifications";

export const useMSoS_MarkAllAsRead = () => {
   const { sosAxios } = useSosAxios();

   const qNotifications = useQSoS_Notifications();

   return useMutation({
      mutationFn: () =>
         sosAxios.patch(`/notifications/read-all`).then<TNofs_MarkAllAsRead_Response>(({ data }) => {
            qNotifications.refetch();
            return data;
         }),
   });
};

export type TNofs_MarkAllAsRead_Response = {
   message: string;
};
