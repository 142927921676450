import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useToastContext } from "../../../contexts/ToastContext";

export const useMSoS_Associations_CreateOne = (p?: TAssociationsGateway_CreateOne_Response) => {
   const { sosAxios } = useSosAxios();
   const { toastDispatch } = useToastContext();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Create New One Association is successful!",
            },
         });
         if (p && p.onSuccess) p.onSuccess();
      },
      onError: (error) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Create New One Association is failed!",
            },
         });
         if (p && p.onError) p.onError(error.message);
      },

      mutationFn: ({ records, table }: TAssociationsGateway_CreateOne_Body) =>
         sosAxios
            .post(`/associations`, { records, table })
            .then<TAssociationsGateway_CreateOne_Response>(({ data }) => data),
   });
};

type TAssociationsGateway_CreateOne_Response = Partial<{
   onSuccess?: () => void;
   onError?: (message: string) => void;
}>;

export type TAssociationsGateway_CreateOne_Body = {
   table:
      | "sos_rel_sources_properties"
      | "sos_rel_contacts_sources"
      | "sos_rel_properties_units"
      | "sos_rel_contacts_properties";
   records: Record<string, any>[];
};
