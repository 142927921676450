import classNames from "classnames/bind";
import styles from "./OffersOffersSection.module.scss";
import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { OfferCard } from "./OffersCard/OffersCard";
import { OffersCreateAndUpdateOfferModal } from "../../OffersCreateAndUpdateOfferModal/OffersCreateAndUpdateOfferModal";
import { useQOffers_Lead } from "../../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { useOffersContext } from "../../../../contexts/OffersContext";

const cx = classNames.bind(styles);
export const OffersOffersSection = () => {
   const { offersState, offersDispatch } = useOffersContext();
   const { showCreateAndUpdateOfferModal } = offersState;

   const qOffersGetLead = useQOffers_Lead();

   if (!qOffersGetLead) return;
   const offers = qOffersGetLead.data?.lead?.offers;

   return (
      <div className={cx("container")}>
         <div className={cx("addOfferC")}>
            <span>Offers</span>
            <SHrSosButton
               type="text"
               buttonProps={{
                  className: cx("btn"),
                  onClick: () => offersDispatch({ overwrite: { showCreateAndUpdateOfferModal: true } }),
               }}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
               </div>
               Create new Offer
            </SHrSosButton>
         </div>

         <div className={cx("offerCardsC")}>
            {!offers ? (
               <div className={cx("spinnerC")}>
                  <SHrSpinner />
                  <span>Loading Offers ...</span>
               </div>
            ) : offers.length === 0 ? (
               <div className={cx("noOffer")}>No offer in this lead!</div>
            ) : (
               offers?.map((offer, i) => <OfferCard key={i} oid={offer.oid} />)
            )}
         </div>

         <SHrPopup
            show={showCreateAndUpdateOfferModal}
            handleShow={(s) => offersDispatch({ overwrite: { showCreateAndUpdateOfferModal: s } })}
         >
            <OffersCreateAndUpdateOfferModal />
         </SHrPopup>
      </div>
   );
};
