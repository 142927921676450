import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { SoS_TermTemplate } from "../../queries/termTemplate/useQOffers_GetTermTemplateById";

export const useMOffers_UpdateOffer = ({ onSuccess, onError }: TUseMOffers_UpdateOffer) => {
   const { sosAxios } = useSosAxios();

   return useMutation({
      onSuccess,
      onError,
      mutationFn: ({ oid, ...updateOfferProps }: TSoS_Offers_UpdateOffer_Props) =>
         sosAxios.patch(`/offers/${oid}`, updateOfferProps).then<TSoS_Offers_UpdateOffer_Response>(({ data }) => data),
   });
};

type TUseMOffers_UpdateOffer = {
   onSuccess: (data: TSoS_Offers_UpdateOffer_Response) => void;
   onError: (message: string) => void;
};

type SoS_Offers_UpdateOffer_Terms = SoS_TermTemplate;

type TSoS_Offers_UpdateOffer_Props = {
   oid: number;
   status?: string;
   type?: string;
   pids?: string[];
   terms?: SoS_Offers_UpdateOffer_Terms;
};

type TSoS_Offers_UpdateOffer_Response = { message: string };
