import classNames from "classnames/bind";
import styles from "./OffersTerms.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { useQOffers_Lead } from "../../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { useSoSOptions_Offers_Simply_Terms } from "../../../../hooks/queries/options/useQSoS_Options";
import { OffersTermPoint } from "./OffersTermPoint/OffersTermPoint";
import { SHrPopup, SHrSpinner } from "@simplyhomes/react";
import { OffersEditTermTemplateLeadDetailModal } from "../../OffersEditTermTemplateModal/OffersEditTermTemplateModal";
import { useState } from "react";

const cx = classNames.bind(styles);

export const OffersTerms = () => {
   const [isOpenTermTemplateModal, setIsOpenTermTemplateModal] = useState(false);

   const qOffersGetLead = useQOffers_Lead();
   const qOffersTermTemplates = useSoSOptions_Offers_Simply_Terms();

   const termTemplateData = qOffersGetLead.data?.lead.terms;

   return (
      <div className={cx("container")}>
         <div className={cx("headingAndTermsC")}>
            <div className={cx("headingC")}>
               <span>Simply Terms</span>
               <div className={cx("img-icon")} onClick={() => setIsOpenTermTemplateModal(true)}>
                  <img src={CF_CDN_URL("/assets/offer/edit_black.svg")} alt="" />
               </div>
            </div>
            <div className={cx("termsC")}>
               {!termTemplateData ? (
                  <div className={cx("spinnerC")}>
                     <SHrSpinner />
                     <span>Loading Simply Terms ...</span>
                  </div>
               ) : !termTemplateData?.simply?.length ? (
                  <div className={cx("noTerm")}>No simply term in this lead!</div>
               ) : (
                  termTemplateData?.simply?.map((term, i) => (
                     <OffersTermPoint
                        key={i}
                        title={qOffersTermTemplates.data?.options.find((op) => op.value === term.title)?.label || ""}
                        isNumber={typeof term.default === "number"}
                        type={typeof term.default === "number" ? "bold-value" : "bold-title"}
                        value={term.default}
                        format="currency"
                     />
                  ))
               )}
            </div>
         </div>
         <div className={cx("headingAndTermsC")}>
            <div className={cx("headingC")}>
               <span>Additional Terms</span>
               <div className={cx("img-icon")} onClick={() => setIsOpenTermTemplateModal(true)}>
                  <img src={CF_CDN_URL("/assets/offer/edit_black.svg")} alt="" />
               </div>
            </div>
            <div className={cx("additionalTermsC")}>
               {!termTemplateData ? (
                  <div className={cx("spinnerC")}>
                     <SHrSpinner />
                     <span>Loading Additional Terms ...</span>
                  </div>
               ) : !termTemplateData?.additional?.length ? (
                  <div className={cx("noTerm")}>No additional term in this lead!</div>
               ) : (
                  termTemplateData?.additional?.map((term, i) => (
                     <div className={cx("additionalTermC")} key={i}>
                        <span>{term}</span>
                     </div>
                  ))
               )}
            </div>
         </div>

         <SHrPopup show={isOpenTermTemplateModal}>
            <OffersEditTermTemplateLeadDetailModal onClose={() => setIsOpenTermTemplateModal(false)} />
         </SHrPopup>
      </div>
   );
};
