import classNames from "classnames/bind";
import styles from "./OffersLeadDetail.module.scss";
import { useOffersContext } from "../../../contexts/OffersContext";
import { SHrSosButton } from "@simplyhomes/react";
import { OffersLeadDetailSetting } from "./OffersLeadDetailSetting/OffersLeadDetailSetting";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";
import { useQOffers_Lead } from "../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { ESoS_LeadStatus } from "@simplyhomes/utils";
import { database_helpers_createPayloadHelper as db } from "../../../hooks/database/mutations/utils";
import { useMDatabase_UpdateValue } from "../../../hooks/database/mutations/useMDatabase_UpdateValue";
import { useQOffers_GetLeads } from "../../../hooks/offers/queries/leads/useQOffers_GetLeads";
import { SoSSwich } from "../../../components/common/SoSSwich/SoSSwich";

export const cx = classNames.bind(styles);

export const OffersLeadDetail = () => {
   const { offersState } = useOffersContext();
   const { lid } = offersState;

   return (
      <div className={cx("rightC", "_jr_offersLeadDetail")}>
         <OffersLeadDetailTop />
         {lid ? <OffersLeadDetailSetting /> : <NoSelectedLead />}
      </div>
   );
};

const OffersLeadDetailTop = () => {
   const { offersState, offersDispatch } = useOffersContext();

   const { isViewComment } = offersState;

   const qOfferLead = useQOffers_Lead();

   const qOfferLeads = useQOffers_GetLeads();

   const lead_id = { column: "lid", id: qOfferLead.data?.lead.lid || "" };

   const db_L = db("sos_leads", [lead_id], () => {
      qOfferLead.refetch();
      qOfferLeads.refetch();
   });

   const mDb_L_LeadData = useMDatabase_UpdateValue(db_L("status"));

   if (!qOfferLead.data) return null;

   const lead = qOfferLead.data.lead;

   const { name, status } = lead;

   const handleChangeStatus = (newStatus: ESoS_LeadStatus) => mDb_L_LeadData.mutate({ value: newStatus });

   return (
      <div className={cx("rightTopC")}>
         <div className={cx("leadNameC")}>
            <SoSDataPoint type="bold-value" value={name} />
         </div>
         <div className={cx("btnsTopC")}>
            <span>Status</span>
            <span className={cx("lead-status")}>{status}</span>
            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>
            Mark as
            {status !== ESoS_LeadStatus.ACTIVE && (
               <SHrSosButton
                  loading={!!mDb_L_LeadData.isMutating}
                  buttonProps={{
                     className: cx("btn", "lead-active-status"),
                     onClick: () => handleChangeStatus(ESoS_LeadStatus.ACTIVE),
                  }}
               >
                  Active
               </SHrSosButton>
            )}
            {status !== ESoS_LeadStatus.REJECTED && (
               <SHrSosButton
                  loading={!!mDb_L_LeadData.isMutating}
                  type="dangerous"
                  buttonProps={{
                     className: cx("btn", "lead-rejected-status"),
                     onClick: () => handleChangeStatus(ESoS_LeadStatus.REJECTED),
                  }}
               >
                  Rejected
               </SHrSosButton>
            )}
            {status !== ESoS_LeadStatus.LONG_TERM && (
               <SHrSosButton
                  loading={!!mDb_L_LeadData.isMutating}
                  buttonProps={{
                     className: cx("btn", "lead-long-term-status"),
                     onClick: () => handleChangeStatus(ESoS_LeadStatus.LONG_TERM),
                  }}
               >
                  Long Term
               </SHrSosButton>
            )}
            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>
            <span>View Comments</span>
            <SoSSwich
               checked={isViewComment}
               onChange={(b) => {
                  offersDispatch({ overwrite: { isViewComment: b } });
               }}
            />
         </div>
      </div>
   );
};

const NoSelectedLead = () => {
   return (
      <div className={cx("rightBodyC")}>
         <div className={cx("noLeadC")}>Create a lead from the viable properties.</div>
      </div>
   );
};
