import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { usePViQueryParams } from "../hooks/usePViQueryParams";
import useDebounce from "../../react/useDebounce";
import { ESoS_PropertyStatus } from "@simplyhomes/utils/dist/types/sos/properties/types";

export const useQPVi_PropertyStatuses = () => {
   const { sosAxios } = useSosAxios();

   const { filters } = usePViQueryParams();

   const baseParams = { ...filters };

   const dbBaseParams = useDebounce(baseParams, 500);

   const query = useQuery({
      queryKey: ["QPVi_Propertytatuses", dbBaseParams],
      staleTime: 500,
      refetchOnMount: true,
      queryFn: () =>
         sosAxios
            .get("/property-viability/properties/statuses", { params: { ...dbBaseParams } })
            .then(({ data: { statuses } }: { data: PVi_GetPropertyStatuses_Response_Dto }) => {
               const defaultStatuses: string[] = Object.values(ESoS_PropertyStatus);

               const baseStatuses = defaultStatuses.map(
                  (baseStatus) =>
                     statuses.find(({ status }) => status === baseStatus) || { status: baseStatus, count: "0" }
               );

               const newStatuses = statuses.filter(({ status }) => !defaultStatuses.includes(status));

               const sortedStatuses = [...baseStatuses, ...newStatuses];

               const statusMap = sortedStatuses.reduce(
                  (acc, { status, count }) => ({ ...acc, [status]: +count }),
                  {} as Record<string, number>
               );

               return { statuses: sortedStatuses, statusMap };
            }),
   });

   return query;
};

export class PVi_GetPropertyStatuses_PropertyStatus {
   status!: string;
   count!: number;
}

export class PVi_GetPropertyStatuses_Response_Dto {
   statuses!: PVi_GetPropertyStatuses_PropertyStatus[];
}
