import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQSoS_Notifications = () => {
   const { sosAxios } = useSosAxios();

   const query = useQuery({
      queryKey: [`useQSoS_Notifications`],
      refetchInterval: 30 * 1000,
      queryFn: () =>
         sosAxios
            .get(`/notifications`)
            .then<TNofs_GetNotifications_Response>(({ data }: { data: TNofs_GetNotifications_Response }) => data),
   });
   return query;
};

export type TNofs_GetNotifications_Response_Notification_Data = {
   tid: number;
   commenter: string;
   comment: string;
   pid?: string;
   lid?: number;
};

export type TNofs_GetNotifications_Notification = {
   nid: number;
   type: "new-comment-in-subscribed-thread" | "mentioned-in-comment" | "mention-in-thread";
   read: boolean;
   data: TNofs_GetNotifications_Response_Notification_Data;
   created_at: string;
};

export type TNofs_GetNotifications_Response = {
   payload: {
      notifications: TNofs_GetNotifications_Notification[];
   };
};
