import classNames from "classnames/bind";
import styles from "./PViCustomRent.module.scss";
import { useQPVi_Property } from "../../../../../../hooks/property-viability/queries/useQPVi_Property";
import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../../../../utils/CF_CDN_URL";
import { useState } from "react";
import { PViAddExpenseModal } from "../../../PViAddExpenseModal/PViAddExpenseModal";
import { useMPVi_Property_ResetExpenses } from "../../../../../../hooks/property-viability/mutates/underwritingModel/useMPVi_Property_ResetExpenses";
import { PgSoS_SoSProperties_UnderwritingModels_Settings } from "@simplyhomes/utils/dist/types/pg/sos/properties";
import { useMDb_P_UpdateUwModels } from "../../../../../../hooks/database/mutations/useMDatabase_UpdateValue";
import { PViExpensesTable } from "../PViExpensesTable/PViExpensesTable";
import { useMPVi_Property_RentometerRefresh } from "../../../../../../hooks/property-viability/mutates/underwritingModel/useMPVi_Property_RentometerRefresh";
import { PViDataPoint } from "../../../../../../components/PViDataPoint/PViDataPoint";

const cx = classNames.bind(styles);

type CustomRentProps = {
   modelIndex: number;
};

export const PViCustomRent = ({ modelIndex }: CustomRentProps) => {
   const qPVi_Property = useQPVi_Property();

   const mPVi_ResetExpenses = useMPVi_Property_ResetExpenses();

   const { mutate: mutateRentometerRefresh, isPending: isPendingentometerRefresh } =
      useMPVi_Property_RentometerRefresh();

   const [isAddExpenseModalOpen, setIsAddExpenseModalOpen] = useState(false);

   const { property: p } = qPVi_Property.data || {};

   const { underwriting_models, underwriting_models_calculated, underwriting_models_max_prices } = p || {};
   const model = underwriting_models?.[modelIndex];
   const calced = underwriting_models_calculated?.[modelIndex];
   const maxPrice = underwriting_models_max_prices?.[modelIndex];

   const mDb_P_uwModels = useMDb_P_UpdateUwModels({ pid: p?.pid });

   if (!p) return null;
   if (!model || !calced) return null;

   const { rentometer_v1_summary_data: rentometer } = p;

   const { settings } = model;
   const { monthly_rent, vacancy_percentage, am_fees_percentage, pm_fees_percentage } = settings;

   const { cap_rate, noi_monthly, arv_uplift } = calced;

   const { max_price_arv_uplift, max_price_cap_rate } = maxPrice || {};

   const uwLoading = qPVi_Property.isFetching || mDb_P_uwModels.isPending;
   const editSettings = async (p: Partial<PgSoS_SoSProperties_UnderwritingModels_Settings>) => {
      const newModel = { ...model, settings: { ...settings, ...p } };
      const newModels = underwriting_models.map((m, i) => (i === modelIndex ? newModel : m));
      mDb_P_uwModels.mutate({ value: JSON.stringify(newModels) });
   };
   const checkNullish = (v: any) => (v == null ? "---" : undefined);
   return (
      <div className={cx("housingAuthorityC")}>
         <div className={cx("sectionC")}>
            <div className={cx("headingAndRefreshBtn")}>
               <span className={cx("title")}>Rentometer</span>
               <SHrSosButton
                  type="text"
                  loading={isPendingentometerRefresh}
                  buttonProps={{ className: cx("resetBtn"), onClick: () => mutateRentometerRefresh() }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_viability/refresh_black.svg")} alt="" />
                  </div>
                  Refresh
               </SHrSosButton>
            </div>
            <div className={cx("rtmValuesC")}>
               <div className={cx("value")}>
                  <PViDataPoint type={"bold-title"} format="currency" value={rentometer.mean} title="Average" />
               </div>

               <div className={cx("value")}>
                  <PViDataPoint type={"bold-title"} format="currency" value={rentometer.median} title="Median" />
               </div>

               <div className={cx("value")}>
                  <PViDataPoint
                     type={"bold-title"}
                     format="currency"
                     value={rentometer.percentile_25}
                     title="25 percentile"
                  />
               </div>

               <div className={cx("value")}>
                  <PViDataPoint
                     type={"bold-title"}
                     format="currency"
                     value={rentometer.percentile_75}
                     title="75 percentile"
                  />
               </div>
            </div>
         </div>

         <hr className={cx("divide")} />
         <div className={cx("sectionC")}>
            <span className={cx("title")}>Settings</span>
            <div className={cx("rtmValuesC")}>
               <div className={cx("value")}>
                  <PViDataPoint
                     type={"bold-title"}
                     format="percentage"
                     displayValue={checkNullish(vacancy_percentage)}
                     value={vacancy_percentage}
                     title="Vacancy %"
                     loading={uwLoading}
                     onNonDatabaseChange={(v) => editSettings({ vacancy_percentage: +v })}
                  />
               </div>
               <div className={cx("value")}>
                  <PViDataPoint
                     type={"bold-title"}
                     format="percentage"
                     displayValue={checkNullish(am_fees_percentage)}
                     value={am_fees_percentage}
                     title="AM %"
                     loading={uwLoading}
                     onNonDatabaseChange={(v) => editSettings({ am_fees_percentage: +v })}
                  />
               </div>
               <div className={cx("value")}>
                  <PViDataPoint
                     type={"bold-title"}
                     format="percentage"
                     displayValue={checkNullish(pm_fees_percentage)}
                     value={pm_fees_percentage}
                     title="PM %"
                     loading={uwLoading}
                     onNonDatabaseChange={(v) => editSettings({ pm_fees_percentage: +v })}
                  />
               </div>
            </div>
         </div>

         <hr className={cx("divide")} />
         <div className={cx("sectionC")}>
            <span className={cx("title")}>Performance</span>
            <div className={cx("rtmValuesC")}>
               <div className={cx("value")}>
                  <PViDataPoint
                     type={"bold-value"}
                     format="currency"
                     displayValue={checkNullish(monthly_rent)}
                     value={Number(monthly_rent)}
                     title="Gross Rent"
                     additionalInfor={"/month"}
                     onNonDatabaseChange={(v) => editSettings({ monthly_rent: +v })}
                     loading={uwLoading}
                  />
                  <PViDataPoint
                     type={"bold-value"}
                     format="currency"
                     displayValue={checkNullish(monthly_rent)}
                     value={Number(monthly_rent) * 12}
                     additionalInfor={"/year"}
                     onNonDatabaseChange={(v) => editSettings({ monthly_rent: +v / 12 })}
                     loading={uwLoading}
                  />
               </div>

               <div className={cx("value")}>
                  <PViDataPoint
                     type={"bold-value"}
                     format="currency"
                     value={noi_monthly}
                     title="NOI"
                     additionalInfor="/month"
                     loading={uwLoading}
                  />
                  <PViDataPoint
                     type={"bold-value"}
                     format="currency"
                     value={noi_monthly * 12}
                     additionalInfor="/year"
                  />
               </div>

               <div className={cx("value")}>
                  <PViDataPoint type={"bold-value"} format="percentage" value={cap_rate} title="Cap rate" />
               </div>
               <div className={cx(`value`)}>
                  <PViDataPoint type={"bold-value"} format="percentage" value={arv_uplift} title="ARV Uplift" />
               </div>

               <div className={cx("value", "grayBg")}>
                  <PViDataPoint
                     type={"bold-value"}
                     format="currency"
                     value={max_price_arv_uplift}
                     title="Max price"
                     additionalInfor="(ARV uplift)"
                  />
                  <PViDataPoint
                     type={"bold-value"}
                     additionalInfor="(Cap rate)"
                     format="currency"
                     value={max_price_cap_rate}
                  />
               </div>
            </div>
         </div>

         <div className={cx("tableExpensesC")}>
            <SHrSosButton
               type="text"
               loading={mPVi_ResetExpenses.isPending}
               buttonProps={{ className: cx("resetBtn"), onClick: () => mPVi_ResetExpenses.mutate({ modelIndex }) }}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/refresh_black.svg")} alt="" />
               </div>
               Reset Table
            </SHrSosButton>
            <div className={cx("tableContainer")}>
               <PViExpensesTable modelIndex={modelIndex} />
            </div>

            <div className={cx("addExpensesC")}>
               <SHrSosButton
                  type="text"
                  buttonProps={{ className: cx("btn"), onClick: () => setIsAddExpenseModalOpen(true) }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/add_black.svg")} alt="" />
                  </div>
                  Add Expense
               </SHrSosButton>
            </div>
         </div>

         <SHrPopup show={isAddExpenseModalOpen}>
            <PViAddExpenseModal modelIndex={modelIndex} onClose={() => setIsAddExpenseModalOpen(false)} />
         </SHrPopup>
      </div>
   );
};
