import classNames from "classnames/bind";
import styles from "./PViGridView.module.scss";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { usePViContext } from "../../../contexts/PViContext";
import { PViPropertyCard } from "../PViPropertyCard/PViPropertyCard";
import { useQPVi_Properties } from "../../../hooks/property-viability/queries/useQPVi_Properties";

const cx = classNames.bind(styles);

export const PViGridView = () => {
   const { pviOverwrite } = usePViContext();

   const qPVi_Properties = useQPVi_Properties();

   const { properties = [] } = qPVi_Properties;

   const handleClickProperty = (pid: string) => pviOverwrite({ pid, showDetailsModal: true });

   if (qPVi_Properties.isLoading) {
      return (
         <div className={cx("loading")}>
            <SHrSpinner sideLength="32px" /> <span>Loading Properties...</span>
         </div>
      );
   }

   return (
      <div className={cx("viewC")}>
         {properties.map((property, index) => (
            <PViPropertyCard
               property={property}
               key={property.pid + index}
               onClick={() => handleClickProperty(property.pid)}
            />
         ))}

         <div className={cx("footer")}>
            <SHrSosButton
               buttonProps={{
                  onClick: () => qPVi_Properties.fetchNextPage(),
                  className: cx("load-more", {
                     hidden: !qPVi_Properties.hasNextPage || qPVi_Properties.isFetchingNextPage,
                  }),
               }}
            >
               Load More
            </SHrSosButton>
         </div>
      </div>
   );
};
