import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQSOS_Contacts = () => {
   const { sosAxios } = useSosAxios();

   const query = useQuery({
      queryKey: [`useQSOS_Contacts`],
      staleTime: 500,
      queryFn: () => sosAxios.get(`/contacts`).then<TSoS_Contacts_GetContacts_Response>(({ data }) => data),
   });
   return query;
};

export class TSoS_Contacts_GetContacts_Contact {
   cid!: number;
   name!: string;
   phone!: string;
   phone_secondary!: string;
   email!: string;
}

export type TSoS_Contacts_GetContacts_Response = {
   contacts: TSoS_Contacts_GetContacts_Contact[];
};
