import classNames from "classnames/bind";
import styles from "./OffersViablePropertyCard.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SHu_js_currencyFormat } from "@simplyhomes/utils";
import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import { SoSCopyToClipboard } from "../../../components/common/SoSCopyToClipboard/SoSCopyToClipboard";
import { useQOffers_GetViableProperties } from "../../../hooks/offers/queries/useQOffers_GetViableProperties";
import { usePViContext } from "../../../contexts/PViContext";
import { useState } from "react";
import { OffersCreateNewLeadModal } from "../OffersCreateNewLeadModal/OffersCreateNewLeadModal";

const cx = classNames.bind(styles);

export const OffersViablePropertyCard = ({ pid }: TOffersPropertyCardProps) => {
   const { pviOverwrite } = usePViContext();

   const [isOpenCreateLeadModal, setIsOpenCreateLeadModal] = useState(false);

   const qOfferViableProperties = useQOffers_GetViableProperties();

   if (!qOfferViableProperties.data) return;
   const { propertiesMap } = qOfferViableProperties.data;
   const property = propertiesMap[pid];

   if (!property) return null;

   const { street_view_url_150, asking_price, address_one_line, sid, max_price_arv_uplift } = property;

   const handleShowDetailsModal = () => {
      pviOverwrite({ showDetailsModal: true, pid });
   };

   const handleShowCreateLeadModal = () => {
      setIsOpenCreateLeadModal(!isOpenCreateLeadModal);
      pviOverwrite({ pid });
   };

   return (
      <div className={cx("container")}>
         <div className={cx("property-img")}>
            <img src={street_view_url_150} alt="" />
         </div>
         <div className={cx("contentC")}>
            <div className={cx("addressC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/offer/location_on_black.svg")} alt="" />
               </div>
               <span>{address_one_line}</span>
               <SoSCopyToClipboard value={address_one_line} placement="top-left" />
            </div>
            <div className={cx("numbersC")}>
               <div className={cx("price")}>
                  <span>Asking Price</span>
                  <span className={cx("number-price")}>{SHu_js_currencyFormat(asking_price)}</span>
               </div>
               <div className={cx("price")}>
                  <span>Current Max Price</span>
                  <span className={cx("number-price")}>
                     {max_price_arv_uplift ? SHu_js_currencyFormat(max_price_arv_uplift) : "N/A"}
                  </span>
               </div>
            </div>
            <div className={cx("btns")}>
               <SHrSosButton type="outlined" buttonProps={{ className: cx("btn"), onClick: handleShowDetailsModal }}>
                  Review Property
               </SHrSosButton>
               <SHrSosButton
                  buttonProps={{
                     className: cx("btn"),
                     onClick: () => handleShowCreateLeadModal(),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/plus_white.svg")} alt="" />
                  </div>
                  Create Lead
               </SHrSosButton>
            </div>
         </div>
         <SHrPopup show={isOpenCreateLeadModal} handleShow={(s) => setIsOpenCreateLeadModal(s)}>
            <OffersCreateNewLeadModal
               onClose={() => setIsOpenCreateLeadModal(!isOpenCreateLeadModal)}
               pid={pid}
               sid={sid}
            />
         </SHrPopup>
      </div>
   );
};

type TOffersPropertyCardProps = {
   pid: string;
};
