import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQ_GetUser = ({ uid }: GetUserById_Ressponse_Params) => {
   const { sosAxios } = useSosAxios();
   const query = useQuery({
      queryKey: ["useQ_GetUser", uid],
      queryFn: () => sosAxios.get(`/users/${uid}`).then<TSoS_GetUserById_Response>(({ data }) => data),
   });

   return query;
};

type GetUserById_Ressponse_Params = { uid: string };

export class TSoS_GetUserById_User {
   email!: string;
   name!: string;
   picture!: string;
   roles!: string[];
}

export type TSoS_GetUserById_Response = {
   user: TSoS_GetUserById_User;
};
