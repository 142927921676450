import classNames from "classnames/bind";
import styles from "./OffersSource.module.scss";
import { SoSDataPoint } from "../../../../components/SoSDataPoint/SoSDataPoint";
import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { useQOffers_GetPropertyByIdInLead } from "../../../../hooks/offers/queries/leads/useQOffers_GetPropertyByIdInLead";
import { useState } from "react";
import { OffersCreateNewContactModal } from "../../OffersCreateNewContactModal/OffersCreateNewContactModal";
import { formatDate } from "../../OffersLeadCard/OffersLeadCard";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../hooks/database/mutations/utils";
import { useQSOS_Contacts } from "../../../../hooks/contact/queries/useQSOS_Contacts";
import { useSoSOptions_Sources_Source } from "../../../../hooks/queries/options/useQSoS_Options";
import { useOffersContext } from "../../../../contexts/OffersContext";
import { useMSoS_Associations_CreateOne } from "../../../../hooks/associations/mutates/useMSoS_Associations_CreateOne";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useMSoS_Associations_DeteleOne } from "../../../../hooks/associations/mutates/useMSoS_Associations_DeteleOne";

const cx = classNames.bind(styles);

export const OffersSource = () => {
   const { toastDispatch } = useToastContext();

   const qOfferGetPropertyByIdInLead = useQOffers_GetPropertyByIdInLead();

   const qGetContacts = useQSOS_Contacts();

   const { pid } = useOffersContext().offersState;

   const { mutate: mutateCreatePropertyContact, isPending: isPendingCreatePropertyContact } =
      useMSoS_Associations_CreateOne({
         onSuccess: () => {
            qOfferGetPropertyByIdInLead.refetch();
         },
      });

   const { mutate: mutateDeletePropertyContact, isPending: isPendingDeletePropertyContact } =
      useMSoS_Associations_DeteleOne({
         onSuccess: () => {
            qOfferGetPropertyByIdInLead.refetch();
         },
      });

   const property = qOfferGetPropertyByIdInLead.data?.property;

   const source = qOfferGetPropertyByIdInLead.data?.source;

   const qSources_Source = useSoSOptions_Sources_Source();

   const { options: options_sources_source } = qSources_Source?.data || {};

   const [isOpenAddContactModal, setIsOpenAddContactModal] = useState(false);

   const handleCreatePropertyContact = (cid: number) => {
      const cidExist = property?.contacts.find((c) => c.cid === cid);
      if (cidExist) {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "warning",
               message: "This contact is already exist!",
            },
         });
         return;
      }
      mutateCreatePropertyContact({ table: "sos_rel_contacts_properties", records: [{ pid, cid }] });
   };

   const handleRemovePropertyContact = (cid: number) => {
      mutateDeletePropertyContact({ table: "sos_rel_contacts_properties", records: [{ pid, cid }] });
   };

   return (
      <div className={cx("container")}>
         <div className={cx("sourcesC")}>
            <span>Source</span>
            {qOfferGetPropertyByIdInLead.isLoading ? (
               <div className={cx("loading")}>
                  <SHrSpinner />
               </div>
            ) : qOfferGetPropertyByIdInLead.data?.source.contacts.length === 0 ? (
               <div className={cx("noContact")}>No source's contact found</div>
            ) : (
               source?.contacts
                  .sort((a, b) => a.cid - b.cid)
                  .map((contact, i) => (
                     <div className={cx("sourceC")} key={i}>
                        <SoSDataPoint
                           title="Seller Name"
                           type="bold-title"
                           value={contact.name}
                           database={dbcb("sos_contacts", [{ column: "cid", id: contact.cid }], () => {
                              qGetContacts.refetch();
                              qOfferGetPropertyByIdInLead.refetch();
                           })("name")}
                        />
                        <SoSDataPoint
                           title="Seller Phone"
                           type="bold-title"
                           value={contact.phone_primary}
                           database={dbcb("sos_contacts", [{ column: "cid", id: contact.cid }], () => {
                              qGetContacts.refetch();
                              qOfferGetPropertyByIdInLead.refetch();
                           })("phone_primary")}
                        />
                        <SoSDataPoint
                           title="Seller Email"
                           type="bold-title"
                           value={contact.email}
                           database={dbcb("sos_contacts", [{ column: "cid", id: contact.cid }], () => {
                              qGetContacts.refetch();
                              qOfferGetPropertyByIdInLead.refetch();
                           })("email")}
                        />
                        <SoSDataPoint title="Date Created" type="bold-title" value={formatDate(source.created_at)} />
                        <SoSDataPoint
                           title="Source Type"
                           type="bold-title"
                           value={source.source}
                           options={options_sources_source}
                           database={dbcb("sos_sources", [{ column: "sid", id: source.sid || "" }], () =>
                              qOfferGetPropertyByIdInLead.refetch()
                           )("source")}
                        />
                     </div>
                  ))
            )}
         </div>
         <div className={cx("additionContactC")}>
            <div className={cx("titleC")}>
               <span>Additional Contacts</span>
               <SHrSosButton
                  type="text"
                  loading={isPendingCreatePropertyContact}
                  buttonProps={{
                     className: cx("btn"),
                     onClick: () => setIsOpenAddContactModal(true),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
                  </div>
                  New contact
               </SHrSosButton>
            </div>
            {qOfferGetPropertyByIdInLead.isLoading ? (
               <div className={cx("loading")}>
                  <SHrSpinner />
               </div>
            ) : qOfferGetPropertyByIdInLead.data?.property.contacts.length === 0 ? (
               <div className={cx("noContact")}>No property's contact found</div>
            ) : (
               property?.contacts
                  .sort((a, b) => a.cid - b.cid)
                  .map((contact, i) => (
                     <div className={cx("propertiesC")} key={i}>
                        <div className={cx("dataC")}>
                           <SoSDataPoint
                              title="Name"
                              type="bold-title"
                              value={contact.name}
                              database={dbcb("sos_contacts", [{ column: "cid", id: contact.cid }], () => {
                                 qGetContacts.refetch();
                                 qOfferGetPropertyByIdInLead.refetch();
                              })("name")}
                           />
                           <SoSDataPoint
                              title="Phone"
                              type="bold-title"
                              value={contact.phone_primary}
                              database={dbcb("sos_contacts", [{ column: "cid", id: contact.cid }], () => {
                                 qGetContacts.refetch();
                                 qOfferGetPropertyByIdInLead.refetch();
                              })("phone_primary")}
                           />
                           <SoSDataPoint
                              title="Email"
                              type="bold-title"
                              value={contact.email}
                              database={dbcb("sos_contacts", [{ column: "cid", id: contact.cid }], () => {
                                 qGetContacts.refetch();
                                 qOfferGetPropertyByIdInLead.refetch();
                              })("email")}
                           />
                        </div>

                        {isPendingDeletePropertyContact ? (
                           <SHrSpinner />
                        ) : (
                           <div className={cx("img-icon")} onClick={() => handleRemovePropertyContact(contact.cid)}>
                              <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                           </div>
                        )}
                     </div>
                  ))
            )}
         </div>
         <SHrPopup show={isOpenAddContactModal}>
            <OffersCreateNewContactModal
               onClose={() => setIsOpenAddContactModal(false)}
               onSelection={handleCreatePropertyContact}
            />
         </SHrPopup>
      </div>
   );
};
