import { useQOffers_GetPropertyByIdInLead } from "../../offers/queries/leads/useQOffers_GetPropertyByIdInLead";
import { useQSOS_Contacts } from "../queries/useQSOS_Contacts";

export const useContactsData = () => {
   const qGetContacts = useQSOS_Contacts();
   const qOfferGetPropertyByIdInLead = useQOffers_GetPropertyByIdInLead();

   return {
      qGetContacts,
      qOfferGetPropertyByIdInLead,
   };
};
