import classNames from "classnames/bind";
import styles from "./SoSToolBox.module.scss";
import { SHrSosButton } from "@simplyhomes/react/dist/components/common/SHrSosButton/SHrSosButton";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";

const cx = classNames.bind(styles);

export type ToolboxItem = {
   title?: string;
   icon: keyof typeof icons;
   onClick: () => void;
};

type SoSToolboxProps = {
   masterIcon?: keyof typeof icons;
   items: ToolboxItem[];
};
const icons = {
   export: "export.svg",
   info: "info.svg",
   notes: "notes.svg",
   question_mark: "question_mark.svg",
   refresh: "refresh.svg",
   save: "save.svg",
};

export const SoSToolbox = ({ items, masterIcon = "question_mark" }: SoSToolboxProps) => {
   return (
      <div className={cx("toolbox")}>
         <SHrSosButton buttonProps={{ className: cx("toolboxButton") }}>
            <div className={cx("icon")}>
               <img src={CF_CDN_URL(`/assets/toolbox/${icons[masterIcon]}`)} alt="Export" />
            </div>
         </SHrSosButton>
         {items.map(({ icon, onClick, title }, index) => (
            <SHrSosButton key={index} buttonProps={{ onClick, className: cx("toolboxButton") }}>
               <div className={cx("icon")}>
                  <img src={CF_CDN_URL(`/assets/toolbox/${icons[icon]}`)} alt={title} />
               </div>
               <span>{title}</span>
            </SHrSosButton>
         ))}
      </div>
   );
};
