import { useMDatabase_UpdateValue_Params } from "./useMDatabase_UpdateValue";

export const database = {
   sos_properties: [
      "am_fees_monthly_override",
      "attom_arv",
      "attom_avm",
      "characteristics_construction",
      "characteristics_drain_type",
      "characteristics_foundation",
      "characteristics_roof_age",
      "characteristics_roof_type",
      "characteristics_sewage_type",
      "characteristics_siding",
      "characteristics_stories",
      "characteristics_water_supply",
      "data_verification",
      "feature_has_attic",
      "feature_has_basement",
      "feature_has_common_laundry",
      "feature_has_common_space",
      "feature_has_deck",
      "feature_has_driveway",
      "feature_has_elevator",
      "feature_has_exterior_stairs",
      "feature_has_garage",
      "feature_has_gate",
      "feature_has_patio",
      "feature_has_pool",
      "feature_has_porch",
      "feature_has_sidewalk",
      "feature_has_yard",
      "gross_rent_final",
      "housing_authority_id",
      "insurance_monthly_override",
      "insurance_monthly",
      "pgcalc_max_prices",
      "pgcalc_underwriting_models",
      "pm_fees_monthly_override",
      "pm_fees_monthly",
      "property_type",
      "reasons",
      "estimated_renovation_cost",
      "repair_and_maintainance_monthly",
      "scope_of_work",
      "sqft",
      "target_purchase_price_override",
      "tax_monthly_override",
      "tax_monthly",
      "underwriting_models",
      "utilities_simply_paying_monthly_override",
      "utilities_simply_paying_monthly",
      "vacancy_monthly_override",
      "vacancy_monthly",
      "year_built",
      "simply_acquisition_fee_override",
      "title_insurance_override",
      "transfer_tax_override",
      "locked",
      "closing_cost_override",
      "additional_ownership_reserve",
      "pm_reserve_total_override",
      "pm_onboarding_total_override",
      "estimated_acquisition_cost_override",
      "total_owner_reserves_override",
      "underwriting_hcv_applicable",
   ],
   sos_sources: ["price", "source"],
   sos_units: [
      "bed",
      "bath",
      "access_detail_type",
      "access_detail_code",
      "utilities_config",
      "utilities_tenant_responsibility",
   ],
   sos_leasings: ["type"],
   sos_leads: ["next_actions", "status"],
   sos_neighborhoods: ["name"],
   sos_housing_authorities: ["name"],
   sos_rel_sources_properties: ["price"],
   sos_contacts: ["email", "phone_primary", "name"],
} as const;

export type Database_Table = keyof typeof database;
export type Database_Column = (typeof database)[Database_Table][number];

export const database_helpers_createPayloadHelper =
   (
      table: Database_Table,
      identifiers: useMDatabase_UpdateValue_Params["identifiers"],
      onComplete?: ((success: boolean) => void) | (() => void)
   ) =>
   (column: (typeof database)[typeof table][number]): useMDatabase_UpdateValue_Params => ({
      table,
      column,
      identifiers,
      onComplete,
   });
