import { TableColumn } from "react-data-table-component";
import classNames from "classnames/bind";
import styles from "./SoSChangesHistoryModal.module.scss";
import DataTable from "react-data-table-component";

import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { SHu_js_timeSince } from "@simplyhomes/utils";
import { usePViContext } from "../../contexts/PViContext";
import {
   SoS_Fields_GetFieldsHistory_Response_Audit,
   useQSOS_FieldsHistory,
} from "../../hooks/fields/queries/useQSOS_FieldsHistory";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { useQSOS_FieldsInfo } from "../../hooks/fields/queries/useQSOS_FieldsInfo";
import { SoSDataPoint } from "../../components/SoSDataPoint/SoSDataPoint";

const cx = classNames.bind(styles);

export const SoSChangesHistoryModal = () => {
   const { pviOverwrite, pviState } = usePViContext();

   const { isShowChangesHistoryModal } = pviState;

   return (
      <SHrPopup
         type="resize-right"
         show={isShowChangesHistoryModal}
         handleShow={(b) => pviOverwrite({ isShowChangesHistoryModal: b })}
      >
         <SoSChangesHistory />
      </SHrPopup>
   );
};

export const SoSChangesHistory = () => {
   const { pviOverwrite, pviState } = usePViContext();

   const { history, fieldInfoCurrentvalue } = pviState;

   const qFieldInfo = useQSOS_FieldsInfo({ table: history?.table || "", column: history?.column || "" });

   const qPropHistory = useQSOS_FieldsHistory({
      table: history?.table || "sos_properties",
      record_id: history?.record_id || "",
   });

   const fieldInfor = qFieldInfo.data?.info;

   return !history?.column ? (
      <div className={cx("container")}>
         <div className={cx("topC")}>
            <span>Changes History</span>
            <div className={cx("close-btn")}>
               <button type="button" title="close" onClick={() => pviOverwrite({ isShowChangesHistoryModal: false })}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         {qPropHistory.isLoading ? (
            <div className={`${styles.loading}`}>
               <SHrSpinner />
               <span>Loading history...</span>
            </div>
         ) : (
            <div className={cx("tableC")}>
               <DataTable
                  columns={allHistoryColumn}
                  data={qPropHistory.data?.pages.flatMap((page) => page.payload.audits) || []}
                  customStyles={{
                     headRow: {
                        style: {
                           backgroundColor: "#E2EBF3",
                        },
                     },
                  }}
                  highlightOnHover
               />

               <div className={cx("footer")}>
                  <SHrSosButton
                     buttonProps={{
                        onClick: () => qPropHistory.fetchNextPage(),
                        className: cx("load-more", {
                           hidden: !qPropHistory.hasNextPage || qPropHistory.isFetchingNextPage,
                        }),
                     }}
                  >
                     Load More
                  </SHrSosButton>
               </div>
            </div>
         )}
      </div>
   ) : (
      <div className={cx("container")}>
         <div className={cx("topC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/info_black.svg")} alt="" />
            </div>
            <span>{fieldInfor?.label}</span>
            <div className={cx("close-btn")}>
               <button type="button" title="close" onClick={() => pviOverwrite({ isShowChangesHistoryModal: false })}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         {qFieldInfo.isLoading ? (
            <div className={`${styles.loading}`}>
               <SHrSpinner />
               <span>Loading field information...</span>
            </div>
         ) : (
            <div className={cx("fieldInfoC")}>
               <div className={cx("inforC")}>
                  <span className={cx("title")}>Description</span>
                  <p>{fieldInfor?.description}</p>
               </div>
               <hr className={cx("divider")} />
               <div className={cx("inforC")}>
                  <span className={cx("title")}>Current Value</span>
                  <SoSDataPoint type="bold-value" value={fieldInfoCurrentvalue} />
               </div>
               <hr className={cx("divider")} />
               <div className={cx("inforC")}>
                  <span className={cx("title")}>Calculation Formula</span>
                  <div className={cx("formulaC")}>{fieldInfor?.formula}</div>
               </div>
               <hr className={cx("divider")} />
            </div>
         )}

         {qPropHistory.isLoading ? (
            <div className={`${styles.loading}`}>
               <SHrSpinner />
               <span>Loading history...</span>
            </div>
         ) : (
            <div className={cx("tableTitleC")}>
               <span className={cx("title")}>Changes History</span>

               <div className={cx("tableC")}>
                  <DataTable
                     columns={allHistoryColumn}
                     data={qPropHistory.data?.pages.flatMap((page) => page.payload.audits) || []}
                     customStyles={{
                        headRow: {
                           style: {
                              backgroundColor: "#E2EBF3",
                           },
                        },
                     }}
                     highlightOnHover
                  />

                  <div className={cx("footer")}>
                     <SHrSosButton
                        buttonProps={{
                           onClick: () => qPropHistory.fetchNextPage(),
                           className: cx("load-more", {
                              hidden: !qPropHistory.hasNextPage || qPropHistory.isFetchingNextPage,
                           }),
                        }}
                     >
                        Load More
                     </SHrSosButton>
                  </div>
               </div>
            </div>
         )}
      </div>
   );
   // <div className={cx("container")}>
   //    <div className={cx("topC")}>
   //       {column && (
   //          <div className={cx("img-icon")}>
   //             <img src={CF_CDN_URL("/assets/offer/info_black.svg")} alt="" />
   //          </div>
   //       )}
   //       <span>{column ? fieldInfor?.label : "Changes History"}</span>
   //       <div className={cx("close-btn")}>
   //          <button type="button" title="close" onClick={() => pviOverwrite({ isShowChangesHistoryModal: false })}>
   //             <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
   //          </button>
   //       </div>
   //    </div>

   //    {column &&
   //       (qFieldInfo.isLoading ? (
   //          <div className={`${styles.loading}`}>
   //             <SHrSpinner />
   //             <span>Loading field information...</span>
   //          </div>
   //       ) : (
   //          <div className={cx("fieldInfoC")}>
   //             <div className={cx("inforC")}>
   //                <span className={cx("title")}>Description</span>
   //                <p>{fieldInfor?.description}</p>
   //             </div>
   //             <hr className={cx("divider")} />
   //             <div className={cx("inforC")}>
   //                <span className={cx("title")}>Current Value</span>
   //                <span className={cx("value")}>{SHu_js_currencyFormat(50000)}</span>
   //             </div>
   //             <hr className={cx("divider")} />
   //             <div className={cx("inforC")}>
   //                <span className={cx("title")}>Calculation Formula</span>
   //                <div className={cx("formulaC")}>{fieldInfor?.formula}</div>
   //             </div>
   //             <hr className={cx("divider")} />
   //          </div>
   //       ))}

   //    {qPropHistory.isLoading ? (
   //       <div className={`${styles.loading}`}>
   //          <SHrSpinner />
   //          <span>Loading history...</span>
   //       </div>
   //    ) : (
   //       <div className={cx("tableC")}>
   //          <DataTable
   //             columns={allHistoryColumn}
   //             data={qPropHistory.data?.pages.flatMap((page) => page.payload.audits) || []}
   //             onRowClicked={handleShowDetailInformationClick}
   //             customStyles={{
   //                headRow: {
   //                   style: {
   //                      backgroundColor: "#E2EBF3",
   //                   },
   //                },
   //             }}
   //             highlightOnHover
   //          />

   //          <div className={cx("footer")}>
   //             <SHrSosButton
   //                buttonProps={{
   //                   onClick: () => qPropHistory.fetchNextPage(),
   //                   className: cx("load-more", {
   //                      hidden: !qPropHistory.hasNextPage || qPropHistory.isFetchingNextPage,
   //                   }),
   //                }}
   //             >
   //                Load More
   //             </SHrSosButton>
   //          </div>
   //       </div>
   //    )}
   // </div>
};

export const allHistoryColumn: TableColumn<SoS_Fields_GetFieldsHistory_Response_Audit>[] = [
   {
      name: <strong>Time modified</strong>,
      selector: (i) => `${i.created_at.split("T")[0]}(${SHu_js_timeSince(new Date(i.created_at).getTime())})`,
   },

   { name: <strong>Person</strong>, selector: (i) => i.user_name || i.user_email },
   { name: <strong>Column</strong>, selector: (i) => i.column },
   {
      name: <strong>Old</strong>,
      selector: (i) => (typeof i.old_value !== "string" ? JSON.stringify(i.old_value) : i.old_value),
   },
   {
      name: <strong>New</strong>,
      selector: (i) => (typeof i.new_value !== "string" ? JSON.stringify(i.new_value) : i.new_value),
   },
];
