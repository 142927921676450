import classNames from "classnames/bind";
import { ComponentProps, useEffect, useMemo, useRef, useState } from "react";
import ReactSelect, { components } from "react-select";

import styles from "./SoSFilter.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { TSoSInputSelectProps } from "../SoSInputSelect/SoSInputSelect";
import { useClickOutside } from "../../../hooks/react/useClickOutside";

const cx = classNames.bind(styles);

const SoSFilter = (props: TSoSFilterProps) => {
   const { isMulti, value, options = [], isBoldTitle = false, hasSubValue = false } = props;
   const [menuIsOpen, setMenuIsOpen] = useState(false);
   const [inputValue, setInputValue] = useState("");
   const allOptions: any[] = [...(props.options || []), ...(props.customOptions || [])];
   const hasOptions = allOptions.length;

   const Value = useMemo(
      () =>
         isMulti
            ? value.map((v) => (typeof v === "string" ? { label: v, value: v } : v))
            : typeof value === "string"
            ? { label: value, value }
            : value,
      [isMulti, value]
   );
   const Options = useMemo(
      () => options.map((o) => (typeof o === "string" ? { label: o, value: o, sub: o } : o)),
      [options]
   );

   const { ref: clickOutSideRef } = useClickOutside({ onClickOutside: () => setMenuIsOpen(false) });

   const handleInputChange = (value: string, { action }: { action: string }) => {
      if (action === "input-change") {
         setInputValue(value);
      }
   };

   const filteredOptions = useMemo(
      () => Options.filter((o) => !!o && o.label?.toLowerCase().includes(inputValue.toLowerCase())),
      [inputValue, Options]
   );

   return (
      <div
         ref={clickOutSideRef}
         onClick={(e) => {
            if (menuIsOpen) {
               setMenuIsOpen(false);
               return;
            }
            setMenuIsOpen(true);
         }}
      >
         <ReactSelect
            {...props}
            isSearchable={false}
            isLoading={props.isLoading}
            isMulti={props.isMulti}
            blurInputOnSelect
            placeholder={props.title}
            menuIsOpen={menuIsOpen}
            options={filteredOptions}
            hideSelectedOptions={false}
            className={cx("contentC")}
            filterOption={(o, i) => true}
            value={Value}
            onChange={(e: any) => props.onChange(e)}
            onInputChange={handleInputChange}
            components={{
               Option: (o) => Option({ ...o, hasSubValue }),
               Control,
               ValueContainer: (v) => ValueContainer({ ...v, isBoldTitle }),
               Input,
               MultiValue,
               IndicatorsContainer,
               LoadingIndicator,
               IndicatorSeparator: () => null,
               Menu: (p) => (hasOptions ? Menu({ ...p }) : null),
               MenuList,
            }}
         />
      </div>
   );
};

const Option = (props: ComponentProps<typeof components.Option> & { hasSubValue: boolean }) => {
   return (
      <components.Option {...props} className={cx("options")}>
         <input title="sos-filter" type="checkbox" checked={props.isSelected} onChange={() => null} />
         {props.hasSubValue ? (
            <div className={cx("labelSubC")}>
               <label>{props.label}</label>
               <p className={cx("sub")}>{(props.data as any).sub}</p>
            </div>
         ) : (
            <label>{props.label}</label>
         )}
      </components.Option>
   );
};
const Control = (props: ComponentProps<typeof components.Control>) => (
   <components.Control {...props} className={styles.control} />
);

const ValueContainer = (props: ComponentProps<typeof components.ValueContainer> & { isBoldTitle: boolean }) => {
   const [values] = props.children as any;
   const { selectProps, isBoldTitle } = props;
   const selectedValues = values && values.length ? `(${values.length})` : "";
   const title = selectProps.placeholder || "Select";

   return (
      <components.ValueContainer {...props} className={styles.valueContainer}>
         {!selectedValues ? (
            <span style={{ fontWeight: isBoldTitle ? "bold" : "normal" }}>{title}</span>
         ) : (
            <span style={{ fontWeight: "bold" }}>
               {title} {selectedValues}
            </span>
         )}
      </components.ValueContainer>
   );
};

const MultiValue = (props: ComponentProps<typeof components.MultiValue>) => null;

const Input = (props: ComponentProps<typeof components.Input>) => (
   <components.Input {...props} className={styles.input} />
);

const IndicatorsContainer = (props: ComponentProps<typeof components.IndicatorsContainer>) => (
   <components.IndicatorsContainer {...props} className={styles.indicatorsContainer} />
);

const LoadingIndicator = (props: ComponentProps<typeof components.LoadingIndicator>) => (
   <components.LoadingIndicator {...props} className={styles.loadingIndicator} />
);

const Menu = (props: ComponentProps<typeof components.Menu>) => <components.Menu {...props} className={styles.menu} />;

const MenuList = ({ ...props }: ComponentProps<typeof components.MenuList>) => {
   const { onInputChange, inputValue, onMenuInputFocus } = props.selectProps as any;
   const inputRef = useRef(null);

   useEffect(() => {
      if (inputRef.current) {
         (inputRef.current as any).focus();
      }
   }, [inputValue]);

   return (
      <>
         <div className={cx("input_search")}>
            <input
               type="text"
               placeholder="Seach..."
               value={inputValue}
               onChange={(e) =>
                  onInputChange(e.currentTarget.value, {
                     action: "input-change",
                  })
               }
               onMouseDown={(e: any) => {
                  e.stopPropagation();
               }}
               onFocus={onMenuInputFocus}
               ref={inputRef}
            />
            <div onClick={() => {}} className={cx("image_icon")}>
               <img src={CF_CDN_URL("/assets/search.svg")} alt="" />
            </div>
         </div>
         <components.MenuList {...props} className={styles.menuList}>
            {props.children}
         </components.MenuList>
      </>
   );
};

export default SoSFilter;

type TSoSFilterProps = TSoSInputSelectProps & {
   title?: string;
   type?: "select" | "range";
   isBoldTitle?: boolean;
   hasSubValue?: boolean;
};
