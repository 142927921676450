import { useMutation } from "@tanstack/react-query";
import { TPropertyCompact } from "../../../contexts/CsvUpContext";
import { useAioAxios } from "../../axios/useAioAxios";

export const useMCU_CreateProperty = () => {
   const { aioAxios } = useAioAxios();
   return useMutation({
      mutationFn: (createPropertiesProps: CreatePropertiesProps) =>
         aioAxios
            .post("/csv-uploader/submit/cleveland-mls", { properties: createPropertiesProps.properties })
            .then(({ data }) => {
               return data;
            }),
   });
};

export type CreatePropertiesProps = {
   properties: TPropertyCompact[];
};
