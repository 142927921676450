import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useMDatabase_DownloadFile = ({ fuid, fileNameAndExtension }: useMDatabase_DownloadFile_Params) => {
   const { sosAxios } = useSosAxios();
   const mutationKey = ["useMDatabase_DownloadFile", fuid];
   const mutation = useMutation({
      mutationKey,
      mutationFn: () =>
         sosAxios.get(`/database/files/${fuid}`, { responseType: "blob" }).then(({ data }) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileNameAndExtension);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
         }),
   });
   const isMutating = useIsMutating({ mutationKey });
   return { ...mutation, isMutating };
};

export type useMDatabase_DownloadFile_Params = {
   fuid: string | number;
   fileNameAndExtension: string;
};
// export const useMDatabase_DownloadFile = async (fileUrl: string, fileName: string) => {
//    const { sosAxios } = useSosAxios();

//    try {
//       const response = await sosAxios.get(fileUrl, {
//          responseType: 'blob', // Important for downloading files
//       });

//       // Create a URL for the file
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', fileName); // Set the file name
//       document.body.appendChild(link);
//       link.click();

//       // Clean up and remove the link
//       link.parentNode?.removeChild(link);
//    } catch (error) {
//       console.error('Error downloading the file', error);
//    }
// };
