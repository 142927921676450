import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { Database_Column, database_helpers_createPayloadHelper as db, Database_Table } from "./utils";
import { useToastContext } from "../../../contexts/ToastContext";
import { useQPVi_Property } from "../../property-viability/queries/useQPVi_Property";

export const useMDatabase_UpdateValue = (p?: useMDatabase_UpdateValue_Params) => {
   const { table, column, identifiers, onComplete = () => {}, muteToast } = p || {};
   const { sosAxios } = useSosAxios({ redirectOnError: false });
   const { toastDispatch } = useToastContext();
   const mutationKey = [table, column, identifiers];

   const mutation = useMutation({
      mutationKey,
      onSuccess: () => {
         onComplete(true);
         if (!muteToast) toastDispatch({ type: "ADD_TOAST", payload: { type: "success", message: "Value Updated" } });
      },
      onError: () => {
         onComplete(false);
         if (!muteToast) toastDispatch({ type: "ADD_TOAST", payload: { type: "error", message: "Invalid Value" } });
      },
      mutationFn: ({ value }: { value: string | number | boolean | (string | number)[] }) =>
         sosAxios.patch("/database/update-value", { table, column, identifiers, value }),
   });
   const isMutating = useIsMutating({ mutationKey });

   return { ...mutation, isMutating };
};

export type useMDatabase_UpdateValue_Params = {
   table: Database_Table;
   column: Database_Column;
   identifiers: { column: string; id: string | number }[];
   onComplete?: (isSuccess: boolean) => void;
   muteToast?: boolean;
};

type useMDb_P_UpdateUwModels_Params = {
   pid: string | undefined;
   onComplete?: (isSuccess: boolean) => void;
};
export const useMDb_P_UpdateUwModels = ({ pid, onComplete }: useMDb_P_UpdateUwModels_Params) => {
   const { toastDispatch } = useToastContext();
   const qProp = useQPVi_Property();
   const OnComplete = (isSuccess: boolean) => {
      if (onComplete) onComplete(isSuccess);
      qProp.refetch();
      toastDispatch({
         type: "ADD_TOAST",
         payload: {
            type: isSuccess ? "success" : "error",
            message: isSuccess
               ? "Updated Underwriting Models is successful!"
               : "Updated Underwriting Models is failed!",
         },
      });
   };
   const propId = [{ column: "pid", id: pid || "" }];
   const db_P_uwModelsRecalc = db("sos_properties", propId);
   const mDb_P_uwModelsRecalc = useMDatabase_UpdateValue({
      ...db_P_uwModelsRecalc("pgcalc_underwriting_models"),
      onComplete: OnComplete,
      muteToast: true,
   });
   const db_P_uwModels = db("sos_properties", propId, () => mDb_P_uwModelsRecalc.mutate({ value: true }));
   return useMDatabase_UpdateValue({ ...db_P_uwModels("underwriting_models"), muteToast: true });
};

