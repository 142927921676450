import { createContext, useContext, useReducer } from "react";
import { SoS_GetTermTemplateById_TermTemplate_Data_Field } from "../hooks/offers/queries/termTemplate/useQOffers_GetTermTemplateById";

class OffersState {
   ttid: number = 0;
   lid: number = 0;
   pid: string = "";
   oid: number = 0;
   ttName: string = "";
   termTemplateData: SoS_GetTermTemplateById_TermTemplate_Data_Field = {
      simply: [],
      additional: [],
   };
   leadTermTemplateData: SoS_GetTermTemplateById_TermTemplate_Data_Field = {
      simply: [],
      additional: [],
   };
   showDetailsModal: boolean = false;
   showCreateAndUpdateOfferModal: boolean = false;
   isOpenListViableProperties: boolean = false;
   isViewComment: boolean = false;
   isRunStepJoyRideInLead: boolean = false;
}

type TOffersAction =
   | { type: "add-new-simply-term-item" }
   | { type: "update-simply-term-item"; payload: { index: number; title: string; default: string } }
   | { type: "remove-simply-term-item"; payload: { index: number } }
   | { type: "remove-additional-term-item"; payload: { index: number } }
   | { type: "add-new-additional-term-item" }
   | { type: "update-additional-term-item"; payload: { index: number; value: string } }
   | { type: "add-new-simply-term-item-in-lead-detail" }
   | { type: "update-simply-term-item-in-lead-detail"; payload: { index: number; title: string; default: string } }
   | { type: "remove-simply-term-item-in-lead-detail"; payload: { index: number } }
   | { type: "remove-additional-term-item-in-lead-detail"; payload: { index: number } }
   | { type: "add-new-additional-term-item-in-lead-detail" }
   | { type: "update-additional-term-item-in-lead-detail"; payload: { index: number; value: string } }
   | { type: "add-properties-create-lead"; payload: { pid: string } }
   | { overwrite: TOffersOverwrite };

type TOffersOverwrite = Partial<OffersState>;

export const OffersContext = createContext<{ offersState: OffersState; offersDispatch: React.Dispatch<TOffersAction> }>(
   {
      offersState: new OffersState(),
      offersDispatch: () => {},
   }
);

const offersReducer = (state: OffersState, action: TOffersAction): OffersState => {
   if ("overwrite" in action) {
      return { ...state, ...action.overwrite };
   }
   switch (action.type) {
      case "add-new-simply-term-item": {
         return {
            ...state,
            termTemplateData: {
               ...state.termTemplateData,
               simply: [...state.termTemplateData.simply, { title: "", default: "" }],
            },
         };
      }
      case "update-simply-term-item": {
         const { index, title, default: defaultValue } = action.payload;
         return {
            ...state,
            termTemplateData: {
               ...state.termTemplateData,
               simply: state.termTemplateData.simply.map((s, i) =>
                  i === index ? { ...s, title, default: defaultValue } : s
               ),
            },
         };
      }
      case "remove-simply-term-item": {
         const { index } = action.payload;
         return {
            ...state,
            termTemplateData: {
               ...state.termTemplateData,
               simply: state.termTemplateData.simply.filter((_, i) => i !== index),
            },
         };
      }
      case "add-new-additional-term-item": {
         return {
            ...state,
            termTemplateData: {
               ...state.termTemplateData,
               additional: [...state.termTemplateData.additional, ""],
            },
         };
      }
      case "update-additional-term-item": {
         const { index, value } = action.payload;
         return {
            ...state,
            termTemplateData: {
               ...state.termTemplateData,
               additional: state.termTemplateData.additional.map((a, i) => (i === index ? value : a)),
            },
         };
      }
      case "remove-additional-term-item": {
         const { index } = action.payload;
         return {
            ...state,
            termTemplateData: {
               ...state.termTemplateData,
               additional: state.termTemplateData.additional.filter((_, i) => i !== index),
            },
         };
      }
      case "add-new-simply-term-item-in-lead-detail": {
         return {
            ...state,
            leadTermTemplateData: {
               ...state.leadTermTemplateData,
               simply: [...state.leadTermTemplateData.simply, { title: "", default: "" }],
            },
         };
      }
      case "update-simply-term-item-in-lead-detail": {
         const { index, title, default: defaultValue } = action.payload;
         return {
            ...state,
            leadTermTemplateData: {
               ...state.leadTermTemplateData,
               simply: state.leadTermTemplateData.simply.map((s, i) =>
                  i === index ? { ...s, title, default: defaultValue } : s
               ),
            },
         };
      }
      case "remove-simply-term-item-in-lead-detail": {
         const { index } = action.payload;
         return {
            ...state,
            leadTermTemplateData: {
               ...state.leadTermTemplateData,
               simply: state.leadTermTemplateData.simply.filter((_, i) => i !== index),
            },
         };
      }
      case "add-new-additional-term-item-in-lead-detail": {
         return {
            ...state,
            leadTermTemplateData: {
               ...state.leadTermTemplateData,
               additional: [...state.leadTermTemplateData.additional, ""],
            },
         };
      }
      case "update-additional-term-item-in-lead-detail": {
         const { index, value } = action.payload;
         return {
            ...state,
            leadTermTemplateData: {
               ...state.leadTermTemplateData,
               additional: state.leadTermTemplateData.additional.map((a, i) => (i === index ? value : a)),
            },
         };
      }
      case "remove-additional-term-item-in-lead-detail": {
         const { index } = action.payload;
         return {
            ...state,
            leadTermTemplateData: {
               ...state.leadTermTemplateData,
               additional: state.leadTermTemplateData.additional.filter((_, i) => i !== index),
            },
         };
      }
   }
   return state;
};

export const OffersProvider = ({ children }: { children: React.ReactNode }) => {
   const [offersState, offersDispatch] = useReducer(offersReducer, new OffersState());

   return (
      <OffersContext.Provider value={{ offersState: offersState, offersDispatch: offersDispatch }}>
         {children}
      </OffersContext.Provider>
   );
};

export const useOffersContext = () => {
   const context = useContext(OffersContext);
   const offersOverwrite = (overwrite: TOffersOverwrite) => context.offersDispatch({ overwrite });
   return { ...context, offersOverwrite };
};
