import { useQuery } from "@tanstack/react-query";

import { useSosAxios } from "../../../axios/useSosAxios";

export const useQOffers_GetTermTemplateById = ({ ttid, cb }: TUseQOffers_GetTermTemplateByIdProps) => {
   const { sosAxios } = useSosAxios();

   return useQuery({
      enabled: !!ttid,
      queryKey: [`useQOffers_GetTermTemplateById`, ttid],
      queryFn: () =>
         sosAxios.get(`/offers/term-templates/${ttid}`).then<SoS_GetTermTemplateById_Response>(({ data }) => {
            if (!data) return;
            if (cb) cb(data);
            return data;
         }),
   });
};

type TUseQOffers_GetTermTemplateByIdProps = {
   ttid: number;
   cb?: (data: SoS_GetTermTemplateById_Response) => void;
};

export type SoS_GetTermTemplateById_TermTemplate_Data_Field = {
   simply: TSoS_TermTemplateSimply;
   additional: string[];
};

export type SoS_TermTemplate = SoS_GetTermTemplateById_TermTemplate_Data_Field;

export type TSoS_TermTemplateSimply = {
   title: string;
   default: string | number;
}[];

export type SoS_GetTermTemplateById_TermTemplate = {
   tid: number;
   name: string;
   data: SoS_GetTermTemplateById_TermTemplate_Data_Field;
};

type SoS_GetTermTemplateById_Response = { template: SoS_GetTermTemplateById_TermTemplate };
