import classNames from "classnames/bind";
import styles from "./OffersAddPropertiesToCreateOfferModal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { useQOffers_Lead } from "../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { OffersPropertiesSelection } from "../OffersPropertiesSelection/OffersPropertiesSelection";
import { useState } from "react";
import { useQOffers_GetLeadSourceProperties } from "../../../hooks/offers/queries/leads/useQOffers_GetLeadSourceProperties";

const cx = classNames.bind(styles);

export const OffersAddPropertiesToCreateOfferModal = ({
   onClose,
   pidsInit,
   handleApplyProperties,
}: OffersAddPropertiesToCreateOfferModalProps) => {
   const qOffersGetLead = useQOffers_Lead();
   const { data } = qOffersGetLead;
   const sid = data?.lead.sid!;
   const qOffersGetLeadSourceProperties = useQOffers_GetLeadSourceProperties({ sid });
   const qOffersPropertyIds = qOffersGetLeadSourceProperties.data?.properties.map((p) => p.pid) || [];

   const [pids, setPids] = useState<string[]>(pidsInit || qOffersPropertyIds.map((pid) => pid) || []);

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/add_box_black.svg")} alt="" />
            </div>
            <span>Choose Properties </span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         <div className={cx("contentC")}>
            <div className={cx("propertiesC")}>
               {qOffersGetLeadSourceProperties.isLoading ? (
                  <div className={cx("loading")}>
                     <SHrSpinner /> <span>Loading Properties...</span>
                  </div>
               ) : (
                  qOffersGetLeadSourceProperties.data?.properties.map((property, i) => (
                     <OffersPropertiesSelection
                        value={pids.includes(property.pid)}
                        onSelectProperty={(value) => {
                           if (value) setPids([...pids, property.pid]);
                           else setPids(pids.filter((pid) => pid !== property.pid));
                        }}
                        property={property}
                        key={i}
                        type="select"
                     />
                  ))
               )}
            </div>
         </div>

         <SoSConfirmWrapper
            title="Are you sure you want to apply these properties to create this offer?"
            content="This action allows to create a new offer."
            type="default"
            onConfirm={() => handleApplyProperties(pids)}
         >
            <div className={cx("btnSave")}>
               <SHrSosButton
                  buttonProps={{
                     className: cx("btn"),
                     disabled: pids.length === 0,
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/plus_white.svg")} alt="" />
                  </div>
                  Apply
               </SHrSosButton>
            </div>
         </SoSConfirmWrapper>
      </div>
   );
};

type OffersAddPropertiesToCreateOfferModalProps = {
   onClose: () => void;
   pidsInit: string[];
   handleApplyProperties: (pids: string[]) => void;
};
