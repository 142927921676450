import classNames from "classnames/bind";
import styles from "./OffersAdditionalTerms.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { OffersTermTemplateActions } from "../OffersEditTermTemplateModal";

const cx = classNames.bind(styles);

type TRowAdditionalTermProps = {
   index: number;
   termTemplateData: string[];
} & Omit<OffersTermTemplateActions, "simplyTermsActions">;

export const RowAdditionalTerms = ({ index, termTemplateData, additionalTermsActions }: TRowAdditionalTermProps) => {
   const term = termTemplateData.find((_, i) => i === index);

   return (
      <div className={cx("additionalTermsC")}>
         <textarea
            onChange={(v) => additionalTermsActions.onUpdate(index, v.target.value)}
            placeholder="Enter your text here"
            value={term}
         />
         <div className={cx("img-icon", "delete")} onClick={() => additionalTermsActions.onRemove(index)}>
            <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
         </div>
      </div>
   );
};
