import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQOffers_GetViableProperties = () => {
   const { sosAxios } = useSosAxios();

   const query = useQuery({
      queryKey: [`useQOffers_GetViableProperties`],
      staleTime: 500,
      refetchInterval: 300000,
      refetchOnMount: true,
      queryFn: () =>
         sosAxios.get(`/offers/properties`).then<SoS_GetViableProperties_Response>(({ data }) => {
            const properties = data.properties;
            const propertiesMap = properties.reduce(
               (acc: Record<string, SoS_GetViableProperties_Property>, property: SoS_GetViableProperties_Property) => {
                  acc[property.pid] = property;
                  return acc;
               },
               {} as Record<string, SoS_GetViableProperties_Property>
            );

            return {
               properties,
               propertiesMap,
               propertyIds: properties.map((property: SoS_GetViableProperties_Property) => property.pid),
            };
         }),
   });
   return query;
};

export type SoS_GetViableProperties_Property = {
   pid: string;
   address_one_line: string;
   asking_price: number;
   max_price_arv_uplift: number;
   street_view_url_150: string;
   sid: number;
};

export type SoS_GetViableProperties_Response = {
   properties: SoS_GetViableProperties_Property[];
   propertiesMap: Record<string, SoS_GetViableProperties_Property>;
   propertyIds: string[];
};
