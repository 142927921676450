import classNames from "classnames/bind";
import styles from "./OffersInspectionAndSource.module.scss";
import { OffersInspection } from "../OffersInspection/OffersInspection";
import { OffersSource } from "../OffersSource/OffersSource";

const cx = classNames.bind(styles);

export const OffersInspectionAndSource = () => {
   return (
      <div className={cx("container")}>
         <OffersSource />
         <OffersInspection />
      </div>
   );
};
