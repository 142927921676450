import classNames from "classnames/bind";
import styles from "./OffersProperties.module.scss";
import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { OffersPropertyCard } from "../../OffersPropertyCard/OffersPropertyCard";
import { useQOffers_Lead } from "../../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { useEffect, useState } from "react";
import { OffersAddPropertiesToLeadModal } from "../../OffersAddPropertiesToLeadModal/OffersAddPropertiesToLeadModal";
import { useOffersContext } from "../../../../contexts/OffersContext";

const cx = classNames.bind(styles);

export const OffersProperties = () => {
   const { offersDispatch } = useOffersContext();

   const qOfferLead = useQOffers_Lead();

   const [isOpenAddPropertyModal, setIsOpenAddPropertyModal] = useState(false);

   const { properties } = qOfferLead.data?.lead || {};

   useEffect(() => {
      if (!properties || !properties.length) return;
      offersDispatch({ overwrite: { pid: properties[0].pid } });
   }, [offersDispatch, properties]);

   return (
      <div className={cx("container")}>
         <div className={cx("addPropertyC")}>
            <span>Properties</span>
            <SHrSosButton
               type="text"
               loading={qOfferLead.isLoading}
               buttonProps={{
                  className: cx("btn"),
                  onClick: () => setIsOpenAddPropertyModal(true),
               }}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
               </div>
               Add Property
            </SHrSosButton>
         </div>
         <div className={cx("propertiesC")}>
            {!properties ? (
               <div className={cx("spinnerC")}>
                  <SHrSpinner />
                  <span>Loading Properties...</span>
               </div>
            ) : (
               properties.map((p, i) => <OffersPropertyCard key={i} pid={p.pid} sid={p.sid} />)
            )}
         </div>

         <SHrPopup show={isOpenAddPropertyModal}>
            <OffersAddPropertiesToLeadModal onClose={() => setIsOpenAddPropertyModal(false)} />
         </SHrPopup>
      </div>
   );
};
