import { CredentialResponse } from "@react-oauth/google";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { constants_paths } from "../../constants/path";
import { useAppContext } from "../../contexts/AppContext";
import { useCredential } from "./useCredential";

export const useSosAuth = () => {
   const { setCredential, removeCredential } = useCredential();
   const { appDispatch } = useAppContext();
   const nav = useNavigate();

   const logIn = ({ credential }: CredentialResponse) => {
      if (!credential) return;
      setCredential(credential);
      appDispatch({ overwrite: { credential } });
      const currentPathname = window.location.pathname;
      nav(currentPathname === constants_paths.SIGN_IN ? constants_paths.HOME : currentPathname);
   };
   const logOut = () => {
      googleLogout();
      removeCredential();
      appDispatch({ overwrite: { credential: "" } });
      nav(constants_paths.SIGN_IN);
   };
   return { logIn, logOut };
};
