import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useMApps_ToggleAppSubscription = ({ onSuccess, onError }: TUseMApps_ToggleAppSubscriptionProps) => {
   const { sosAxios } = useSosAxios();

   return useMutation({
      onError,
      onSuccess,
      mutationFn: (appSubscriptionData: TApps_ToggleAppSubscription_Mutate) =>
         sosAxios
            .post("/user/apps/toggle-subscription", appSubscriptionData)
            .then<TApps_ToggleAppSubscription_Response>(({ data }) => data),
   });
};

type TUseMApps_ToggleAppSubscriptionProps = {
   onSuccess: (data: TApps_ToggleAppSubscription_Response) => void;
   onError: (message: string) => void;
};

type TApps_ToggleAppSubscription_Mutate = {
   appId: string;
   subscribe: boolean;
};

export type TApps_ToggleAppSubscription_Response = {
   message: string;
};
