import classNames from "classnames/bind";
import styles from "./PViAddExpenseModal.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { SHrSosButton } from "@simplyhomes/react";
import { SoSInputTitle } from "../../../../components/common/soSInputTitle/SoSInputTitle";
import { useState } from "react";
import { usePViContext } from "../../../../contexts/PViContext";
import { useQPVi_Property } from "../../../../hooks/property-viability/queries/useQPVi_Property";
import { PgSoS_SoSProperties_UnderwritingModel } from "@simplyhomes/utils/dist/types/pg/sos/properties";
import { useMDb_P_UpdateUwModels } from "../../../../hooks/database/mutations/useMDatabase_UpdateValue";

const cx = classNames.bind(styles);

type TPViAddExpenseModalProps = {
   modelIndex: number;
   onClose: () => void;
};

export const PViAddExpenseModal = ({ modelIndex, onClose }: TPViAddExpenseModalProps) => {
   const { pviState } = usePViContext();

   const qPVi_Property = useQPVi_Property();

   const { pid } = pviState;

   const [expense, setExpense] = useState({ name: "", monthly: 0, yearly: 0 });

   const { property: p } = qPVi_Property.data || {};

   const mDb_P_uwModels = useMDb_P_UpdateUwModels({ pid });

   if (!p) return null;

   const { underwriting_models } = p;

   const model = underwriting_models?.[modelIndex];

   if (!model) return null;

   const handleMonthlyChange = (value: number) => {
      const monthly = Number(value);
      setExpense({
         ...expense,
         monthly,
         yearly: monthly * 12,
      });
   };

   const handleYearlyChange = (value: number) => {
      const yearly = Number(value);
      setExpense({
         ...expense,
         monthly: yearly / 12,
         yearly,
      });
   };

   const handleAddNewExpense = () => {
      const newModel: PgSoS_SoSProperties_UnderwritingModel = {
         ...model,
         expenses: [...(model.expenses || []), expense],
      };

      const newModels = underwriting_models.map((m, i) => (i === modelIndex ? newModel : m));

      mDb_P_uwModels.mutate({ value: JSON.stringify(newModels) });
      onClose();
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/add_box_black.svg")} alt="" />
            </div>
            <span>Add Expense</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         <div className={cx("contentC")}>
            <div className={cx("inputFieldC")}>
               <SoSInputTitle
                  title="Expense Name"
                  inputProps={{ placeholder: "Enter expense name" }}
                  type="text"
                  value={expense.name}
                  handleValue={(v) => setExpense({ ...expense, name: v })}
               />
            </div>

            <div className={cx("inputFieldC")}>
               <SoSInputTitle
                  title="Monthly Cost"
                  inputProps={{ placeholder: "Enter monthly cost", min: 0 }}
                  handleValue={(v) => handleMonthlyChange(Number(v))}
                  value={expense.monthly}
                  type="number"
               />
            </div>

            <div className={cx("inputFieldC")}>
               <SoSInputTitle
                  title="Annually Cost"
                  inputProps={{ placeholder: "Enter annually cost", min: 0 }}
                  handleValue={(v) => handleYearlyChange(Number(v))}
                  value={expense.yearly}
                  type="number"
               />
            </div>
         </div>

         <div className={cx("btnSave")}>
            <SHrSosButton
               buttonProps={{ className: cx("btn"), onClick: handleAddNewExpense }}
               loading={!!mDb_P_uwModels.isMutating}
            >
               Save
            </SHrSosButton>
         </div>
      </div>
   );
};
