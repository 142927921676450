import { useMutation } from "@tanstack/react-query";

import { useQEContext } from "../../../contexts/QEditorContext";
import { useQQE_Questions } from "../../queries/QEditor/useQQE_Questions";
import { useSosAxios } from "../../axios/useSosAxios";

export const useMQE_CreateQuestion = () => {
   const { sosAxios } = useSosAxios();
   const qQuestions = useQQE_Questions();
   const { qeState, qeDispatch } = useQEContext();
   const { qSelected } = qeState;
   return useMutation({
      mutationFn: () =>
         sosAxios.post("/qeditor/questions", { question: qSelected }).then<TData>(({ data }) => {
            qQuestions.refetch().then(() => qeDispatch({ type: "select-question", payload: { qId: data.qid } }));
            return data;
         }),
   });
};

type TData = { qid: string };
