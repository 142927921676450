import classNames from "classnames/bind";
import styles from "./OffersSettingsTerm.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import { OffersEditTermTemplateModal } from "../OffersEditTermTemplateModal/OffersEditTermTemplateModal";
import { useOffersContext } from "../../../contexts/OffersContext";

const cx = classNames.bind(styles);

export const OffersSettingsTerm = () => {
   const { offersState, offersDispatch } = useOffersContext();
   const { showDetailsModal } = offersState;
   return (
      <div className={cx("settingsC", "_jr_offersDefaultTermsConfig")}>
         <div className={cx("settingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/settings_black.svg")} alt="" />
            </div>
            <span className={cx("title")}>Settings</span>
         </div>
         <SHrSosButton
            type="text"
            buttonProps={{
               className: cx("btn-edit"),
               onClick: () => offersDispatch({ overwrite: { showDetailsModal: !showDetailsModal } }),
            }}
         >
            Edit Terms Template
         </SHrSosButton>

         <SHrPopup show={showDetailsModal}>
            <OffersEditTermTemplateModal />
         </SHrPopup>
      </div>
   );
};
