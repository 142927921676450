import { useJsApiLoader } from "@react-google-maps/api";
import { useAppContext } from "../../contexts/AppContext";
import { useEffect } from "react";

export const useGoogleMapsApiLoader = () => {
   const { appState, appDispatch } = useAppContext();
   const { isGoogleMapsLoaded } = appState;
   const { isLoaded } = useJsApiLoader({
      id: "googlemap",
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
   });
   useEffect(() => {
      if (!isLoaded && !isGoogleMapsLoaded) appDispatch({ overwrite: { isGoogleMapsLoaded: true } });
   }, [appDispatch, isGoogleMapsLoaded, isLoaded]);
   return { isLoaded };
};
