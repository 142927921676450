import { useMutation } from "@tanstack/react-query";
import { useOffersContext } from "../../../../contexts/OffersContext";
import { useSosAxios } from "../../../axios/useSosAxios";

export const useMOffers_Leads_RemoveProperty = ({ onSuccess, onError }: TUseMOffers_Leads_RemovePropertProps) => {
   const { sosAxios } = useSosAxios();

   const { offersState } = useOffersContext();
   const { lid } = offersState;

   return useMutation({
      onSuccess,
      onError,
      mutationFn: ({ pid }: TUseMOffers_Leads_RemoveProperty_Params) =>
         sosAxios
            .delete(`/offers/leads/${lid}/properties/${pid}`)
            .then<TSoS_Offer_DeleteLeadProperties_Response>(({ data }) => data),
   });
};

type TUseMOffers_Leads_RemovePropertProps = {
   onSuccess: (message: TSoS_Offer_DeleteLeadProperties_Response) => void;
   onError: (message: string) => void;
};

type TUseMOffers_Leads_RemoveProperty_Params = {
   pid: string;
};

type TSoS_Offer_DeleteLeadProperties_Response = { message: string };
