import classNames from "classnames/bind";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NavHorizontal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useSosAuth } from "../../../hooks/auth/useSosAuth";
import { useQSOS_Profile } from "../../../hooks/queries/user/useQSOS_Profile";
import { SHrSosButton, SHrSosTabsBar, SHrSpinner } from "@simplyhomes/react";
import {
   TNofs_GetNotifications_Notification,
   useQSoS_Notifications,
} from "../../../hooks/notifications/queries/useQSoS_Notifications";
import { useQ_GetUser } from "../../../hooks/queries/user/useQ_GetUser";
import { SHu_js_timeSince } from "@simplyhomes/utils";
import { useQConvo_Threads } from "../../../hooks/conversation-api/queries/threads/useQConvo_Threads";
import { useMSoS_MarkAllAsRead } from "../../../hooks/notifications/mutates/useMSoS_MarkAllAsRead";
import { usePViContext } from "../../../contexts/PViContext";
import { useMSoS_ChangeNotificationReadStatus } from "../../../hooks/notifications/mutates/useMSoS_ChangeNotificationReadStatus";
import { useConvoContext } from "../../../contexts/ConvoContext";
import { useOffersContext } from "../../../contexts/OffersContext";
import { handleString } from "../../../pages/SoSConversation/SoSConvoPostCommentModal/SoSConvoPostCommentModal";

const cx = classNames.bind(styles);

const tabs = ["All notifications", "Not Read"];

const NavHorizontal = () => {
   const nav = useNavigate();

   const { logOut } = useSosAuth();

   const qUserProfile = useQSOS_Profile();

   const { data: userProfile } = qUserProfile;

   const qGetNotifications = useQSoS_Notifications();

   const { mutate: mutateMarkAllAsRead, isPending: isPendingMarkAllAsRead } = useMSoS_MarkAllAsRead();

   const getNotifications = qGetNotifications.data;

   const [activeTab, setActiveTab] = useState("All notifications");

   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

   const [isShowNotificationOpen, setIsShowNotificationOpen] = useState(false);

   const profile = userProfile?.profile;

   const notifications = getNotifications?.payload.notifications;

   if (!profile) return null;

   const { picture } = profile;

   const userMenuItems: { icon: string; title: string; cb: () => void }[] = [
      { icon: "person_primary.svg", title: "My Profile", cb: () => nav("/profile") },
      { icon: "settings_primary.svg", title: "Account settings", cb: () => nav("/account-settings") },
      { icon: "help_center_primary.svg", title: "Support center", cb: () => nav("/support-center") },
      { icon: "exit_to_app_primary.svg", title: "Sign out", cb: logOut },
   ];

   return (
      <div className={cx("contentC")}>
         <div className={cx("logo")}>
            <img src={CF_CDN_URL("/assets/nav_logo.svg")} alt="" />
            <span>Simply OS</span>
         </div>

         <div className={cx("notification")}>
            <div className={cx("bellAndNumber")}>
               <div className={cx("new-messages")}>{notifications?.filter((n) => n.read === false).length}</div>

               <div className={cx("img-icon")} onClick={() => setIsShowNotificationOpen(!isShowNotificationOpen)}>
                  <img src={CF_CDN_URL("/assets/notifications_snow.svg")} alt="" />
               </div>
            </div>

            <div className={cx("avt-notification")} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
               <div>{profile?.picture && <img src={picture} alt="" />}</div>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/arrow_drop_down.svg")} alt="" />
               </div>
            </div>

            <div
               className={cx("menu-item-notication", {
                  open: isShowNotificationOpen,
               })}
            >
               <div className={cx("topC")}>
                  <span className={cx("heading")}>Notifications</span>
                  <SHrSosButton
                     type="text"
                     loading={isPendingMarkAllAsRead}
                     buttonProps={{ className: cx("mark"), onClick: () => mutateMarkAllAsRead() }}
                  >
                     Mark all as read
                  </SHrSosButton>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/notification_off_black.svg")} alt="" />
                  </div>
               </div>
               <div className={cx("tabs")}>
                  <SHrSosTabsBar tabs={tabs} value={activeTab} onChange={(v) => setActiveTab(v)} />
               </div>
               {activeTab && activeTab === "All notifications" && (
                  <div className={cx("listItemsC")}>
                     {qGetNotifications.isLoading ? (
                        <div className={cx("loading")}>
                           <SHrSpinner /> <span>Loading Properties...</span>
                        </div>
                     ) : notifications?.length ? (
                        notifications?.map((item) => (
                           <Notification key={item.nid} item={item} setShowNotifications={setIsShowNotificationOpen} />
                        ))
                     ) : (
                        <div className={cx("noData")}>No Notification</div>
                     )}
                  </div>
               )}

               {activeTab && activeTab === "Not Read" && (
                  <div className={cx("listItemsC")}>
                     {qGetNotifications.isLoading ? (
                        <div className={cx("loading")}>
                           <SHrSpinner /> <span>Loading Properties...</span>
                        </div>
                     ) : notifications?.filter((i) => !i.read).length ? (
                        notifications
                           ?.filter((i) => !i.read)
                           .map((item) => (
                              <Notification
                                 key={item.nid}
                                 item={item}
                                 setShowNotifications={setIsShowNotificationOpen}
                              />
                           ))
                     ) : (
                        <div className={cx("noData")}>No Notification</div>
                     )}
                  </div>
               )}
            </div>

            <ul
               className={cx("menu-item", { open: isDropdownOpen })}
               onMouseLeave={() => setIsDropdownOpen(false)}
               onClick={() => setIsDropdownOpen(false)}
            >
               {userMenuItems.map((item, index) => (
                  <li key={index + item.title} onClick={item.cb}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL(`/assets/${item.icon}`)} alt="" />
                     </div>
                     <span>{item.title}</span>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   );
};

type NotificationProps = {
   item: TNofs_GetNotifications_Notification;
   setShowNotifications: (value: boolean) => void;
};

const Notification = ({ item, setShowNotifications }: NotificationProps) => {
   const nav = useNavigate();

   const { pviOverwrite } = usePViContext();

   const { offersOverwrite } = useOffersContext();

   const { convoDispatch } = useConvoContext();

   const { created_at, data, type, read } = item;

   const { commenter, tid, pid, lid, comment } = data;

   const qConvoGetThreads = useQConvo_Threads({ pid, lid });

   const getUser = useQ_GetUser({ uid: commenter });

   const { mutate: mutateChangeReadStatus } = useMSoS_ChangeNotificationReadStatus();

   if (!qConvoGetThreads.data) return null;

   const { threadsMap } = qConvoGetThreads;

   const thread = threadsMap[tid];

   if (!thread) return null;

   const user = getUser.data?.user;

   if (!user) return null;

   const { title } = thread;

   const { picture, name } = user;

   const timestamp = new Date(created_at).getTime();

   const handleNotification = () => {
      setShowNotifications(false);
      if (pid) {
         nav("/apps/property-viability");
         pviOverwrite({ showDetailsModal: true, pid });
         convoDispatch({ overwrite: { tid, showThreadDetailsModal: true } });
         mutateChangeReadStatus({ nid: item.nid, read: true });
         return;
      }
      if (lid) {
         nav("/apps/offers");
         offersOverwrite({ isViewComment: true, lid });
         convoDispatch({ overwrite: { tid, showThreadDetailsModal: true } });
         mutateChangeReadStatus({ nid: item.nid, read: true });
         return;
      }
   };

   return (
      <>
         <div className={cx("itemC")}>
            <div className={cx("readStatus", { "not-read": !read })} />

            <div className={cx("img-icon-avatar")}>
               <img src={picture} alt="" />
            </div>
            {type === "new-comment-in-subscribed-thread" && (
               <div className={cx("messages")}>
                  <span
                     onClick={() => {
                        handleNotification();
                     }}
                     className={cx("messageDetail")}
                  >
                     <b>{name}</b> commented on your subscribed thread (<b>{title}</b>): {handleString(comment)}
                  </span>
                  <span className={cx("time")}>{SHu_js_timeSince(timestamp)}</span>
               </div>
            )}

            {type === "mentioned-in-comment" && (
               <div className={cx("messages")}>
                  <span
                     onClick={() => {
                        handleNotification();
                     }}
                     className={cx("messageDetail")}
                  >
                     <b>{name}</b> mentioned you in a comment: {handleString(comment)}
                  </span>
                  <span className={cx("time")}>{SHu_js_timeSince(timestamp)}</span>
               </div>
            )}
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/more_horiz_black.svg")} alt="" />
            </div>
         </div>
         <hr className={cx("divider")} />
      </>
   );
};

export default NavHorizontal;
