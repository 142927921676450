import classNames from "classnames/bind";
import styles from "./OffersInspection.module.scss";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { useQOffers_GetPropertyByIdInLead } from "../../../../hooks/offers/queries/leads/useQOffers_GetPropertyByIdInLead";
import { SoSDataPoint } from "../../../../components/SoSDataPoint/SoSDataPoint";

const cx = classNames.bind(styles);

export const OffersInspection = () => {
   const qOfferGetPropertyByIdInLead = useQOffers_GetPropertyByIdInLead();
   const property = qOfferGetPropertyByIdInLead.data?.property;
   return (
      <div className={cx("container")}>
         <div className={cx("wrapper")}>
            <div className={cx("addInspectionC")}>
               <span>Inspection</span>
               <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("btn"),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
                  </div>
                  Schedule an inspection
               </SHrSosButton>
            </div>
            {qOfferGetPropertyByIdInLead.isLoading ? (
               <div className={cx("spinnerC")}>
                  <SHrSpinner />
               </div>
            ) : property ? (
               <div className={cx("inspectionC")}>
                  <SoSDataPoint type="bold-title" title="Property Address" value={property?.address_one_line} />
                  <div className={cx("inspection")}>
                     <SoSDataPoint type="bold-title" title="Inspecion Status" value="Scheduled" isMocked />
                     <SoSDataPoint type="bold-title" title="Current Occupancy" value="12:00 PM" isMocked />
                     <div className={cx("inspectionCostC")}>
                        <SoSDataPoint type="bold-value" title="Inspection Cost" format="currency" value={100} />
                     </div>
                  </div>

                  <div className={cx("inspection")}>
                     <SoSDataPoint type="bold-title" title="Reno Person" value="Gwen Millers" isMocked />
                     <SoSDataPoint type="bold-title" title="Date" value="21 June 2023" isMocked />
                     <SoSDataPoint type="bold-title" title="Assigned POC" value="Gwen Stacy" isMocked />
                  </div>

                  <div className={cx("inspection")}>
                     <div className={cx("otherReasonC")}>
                        <span>
                           <b>Access Details</b>
                        </span>
                        <textarea placeholder="Note to the assignee..." value="" onChange={(e) => {}} />
                     </div>

                     <div className={cx("otherReasonC")}>
                        <span>
                           <b>Notes</b>
                        </span>
                        <textarea placeholder="Note to the assignee..." value="" onChange={(e) => {}} />
                     </div>
                  </div>
               </div>
            ) : (
               <div className={cx("noInspectionC")}>
                  <span>No inspection recorded yet.</span>
               </div>
            )}
         </div>

         {/* <div className={cx("leadDocSC")}>
            <div className={cx("docsC")}>
               <div className={cx("docC")}>
                  <span>SOW</span>
                  <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                     Upload a file
                  </SHrSosButton>
                  <div className={cx("filesC")}>
                     <Documentation />
                     <Documentation />
                     <Documentation />
                     <Documentation />
                     <Documentation />
                     <Documentation />
                     <Documentation />
                     <Documentation />
                     <Documentation />
                  </div>
               </div>
               <div className={cx("docC")}>
                  <span>Disclosure Documents</span>
                  <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                     Upload a file
                  </SHrSosButton>
               </div>
            </div>

            <div className={cx("docsC")}>
               <div className={cx("docC")}>
                  <span>Lease Documents</span>
                  <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                     Upload a file
                  </SHrSosButton>
               </div>
               <div className={cx("docC")}>
                  <span>Other Documents</span>
                  <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                     Upload a file
                  </SHrSosButton>
               </div>
            </div>
         </div> */}
      </div>
   );
};

// const Documentation = () => {
//    return (
//       <div className={cx("linkUploadC")}>
//          <div className={cx("img-icon")}>
//             <img src={CF_CDN_URL("/assets/property_validator/picture_as_pdf_black.svg")} alt="" />
//          </div>
//          <span>File name.pdf</span>
//          <div className={cx("linkIconsC")}>
//             <div className={cx("img-icon")}>
//                <img src={CF_CDN_URL("/assets/property_validator/drive_file_rename_outline_black.svg")} alt="" />
//             </div>
//             <div className={cx("img-icon")}>
//                <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
//             </div>
//             <div className={cx("img-icon")}>
//                <img src={CF_CDN_URL("/assets/property_validator/delete_black.svg")} alt="" />
//             </div>
//          </div>
//       </div>
//    );
// };
