import DataTable, { TableColumn } from "react-data-table-component";
import { useQPVi_Property } from "../../../../../../hooks/property-viability/queries/useQPVi_Property";
import {
   Database_Column,
   database_helpers_createPayloadHelper as db,
} from "../../../../../../hooks/database/mutations/utils";
import {
   useMDatabase_UpdateValue,
   useMDb_P_UpdateUwModels,
} from "../../../../../../hooks/database/mutations/useMDatabase_UpdateValue";
import { SHu_js_currencyFormat } from "@simplyhomes/utils";
import classNames from "classnames/bind";
import styles from "./PViExpensesTable.module.scss";
import { CF_CDN_URL } from "../../../../../../utils/CF_CDN_URL";
import { PViDataPoint } from "../../../../../../components/PViDataPoint/PViDataPoint";

const cx = classNames.bind(styles);

type TExpenseData = {
   name: string;
   monthly: number | undefined | null;
   override?: number;
   customExpenseIndex?: number;
   hcvModelOverrideKey?: Database_Column;
   customModelOverrideKey?: string;
};

type TExpensesTableProps = { modelIndex?: number };
export const PViExpensesTable = ({ modelIndex }: TExpensesTableProps) => {
   const isCustomModel = modelIndex != null;

   const qPVi_Property = useQPVi_Property();

   const p = qPVi_Property.data?.property;
   if (!p) return null;

   const { underwriting_models, underwriting_models_calculated } = p;
   const model = modelIndex != null ? underwriting_models?.[modelIndex] : null;
   const calced = modelIndex != null ? underwriting_models_calculated?.[modelIndex] : null;
   const { overrides: mo } = model || {};
   const expensesData: TExpenseData[] = [
      {
         name: "Property Management",
         monthly: isCustomModel ? calced?.pm_fees_monthly : p.pm_fees_monthly,
         override: isCustomModel ? mo?.pm_fees_monthly : p.pm_fees_monthly_override,
         hcvModelOverrideKey: "pm_fees_monthly_override",
         customModelOverrideKey: "pm_fees_monthly",
      },
      {
         name: "Asset Management",
         monthly: isCustomModel ? calced?.am_fees_monthly : p.am_fees_monthly,
         override: isCustomModel ? mo?.am_fees_monthly : p.am_fees_monthly_override,
         hcvModelOverrideKey: "am_fees_monthly_override",
         customModelOverrideKey: "am_fees_monthly",
      },
      {
         name: "Vacancy",
         monthly: isCustomModel ? calced?.vacancy_monthly : p.vacancy_monthly,
         override: isCustomModel ? mo?.vacancy_monthly : p.vacancy_monthly_override,
         hcvModelOverrideKey: "vacancy_monthly_override",
         customModelOverrideKey: "vacancy_monthly",
      },
      {
         name: "Repair And Maintainance",
         monthly: p.repair_and_maintainance_monthly,
         override: isCustomModel ? mo?.repair_and_maintainance_monthly : p.repair_and_maintainance_monthly_override,
         hcvModelOverrideKey: "repair_and_maintainance_monthly",
         customModelOverrideKey: "repair_and_maintainance_monthly",
      },
      {
         name: "Property Insurance",
         monthly: p.insurance_monthly,
         override: isCustomModel ? mo?.insurance_monthly : p.insurance_monthly_override,
         hcvModelOverrideKey: "insurance_monthly_override",
         customModelOverrideKey: "insurance_monthly",
      },
      {
         name: "Tax",
         monthly: p.tax_monthly,
         override: isCustomModel ? mo?.tax_monthly : p.tax_monthly_override,
         hcvModelOverrideKey: "tax_monthly_override",
         customModelOverrideKey: "tax_monthly",
      },
   ];

   if (model?.settings?.simply_paying_utils || modelIndex == null) {
      expensesData.push({
         name: "Utilities",
         monthly: p.utilities_simply_paying_monthly,
         override: isCustomModel ? mo?.utilities_simply_paying_monthly : p.utilities_simply_paying_monthly_override,
         hcvModelOverrideKey: "utilities_simply_paying_monthly_override",
      });
   }

   if (model?.expenses && model.expenses.length) {
      const customExpenses: TExpenseData[] = model.expenses.map((expense, i) => ({
         name: expense.name,
         monthly: expense.monthly,
         customExpenseIndex: i,
      }));
      expensesData.push(...customExpenses);
   }

   const monthlyTotal = expensesData.reduce((sum, expense) => sum + (expense.override || expense.monthly || 0), 0);

   const allExpensesColumn: TableColumn<TExpenseData>[] = [
      { name: "Expenses", cell: (c) => <FirstCell {...c} /> },
      {
         name: (
            <span>
               Monthly <br /> <b>{SHu_js_currencyFormat(monthlyTotal)}</b>
            </span>
         ),
         cell: (c) => <Cell {...c} modelIndex={modelIndex} isMonthly />,
      },
      {
         name: (
            <span>
               Annually <br /> <b>{SHu_js_currencyFormat(monthlyTotal * 12)}</b>
            </span>
         ),
         cell: (c) => <Cell {...c} modelIndex={modelIndex} />,
      },
   ];

   return (
      <DataTable
         columns={allExpensesColumn}
         data={expensesData}
         customStyles={{
            headCells: { style: { fontSize: "16px" } },
            headRow: { style: { backgroundColor: "#E2EBF3" } },
         }}
      />
   );
};

const FirstCell = ({ name, customExpenseIndex }: TExpenseData) => {
   const qPVi_Property = useQPVi_Property();
   const { pid, underwriting_models } = qPVi_Property.data?.property || {};

   const mDb_P_uwModels = useMDb_P_UpdateUwModels({ pid });

   const handleNameChange = (v: string) => {
      if (customExpenseIndex == null || !underwriting_models) return;
      const newModel = {
         ...underwriting_models[customExpenseIndex],
         expenses: underwriting_models[customExpenseIndex].expenses?.map((e, i) =>
            i === customExpenseIndex ? { ...e, name: v } : e
         ),
      };
      mDb_P_uwModels.mutate({
         value: JSON.stringify(underwriting_models.map((m, i) => (i === customExpenseIndex ? newModel : m))),
      });
   };

   const handleRemoveExpense = () => {
      if (customExpenseIndex == null || !underwriting_models) return;
      const newModel = {
         ...underwriting_models[customExpenseIndex],
         expenses: underwriting_models[customExpenseIndex].expenses?.filter((_, i) => i !== customExpenseIndex),
      };
      mDb_P_uwModels.mutate({
         value: JSON.stringify(underwriting_models.map((m, i) => (i === customExpenseIndex ? newModel : m))),
      });
   };

   return (
      <div className={cx(`firstCellC`)}>
         {customExpenseIndex != null && (
            <button type="button" title="delete" onClick={handleRemoveExpense}>
               <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
            </button>
         )}
         <PViDataPoint
            type="bold-value-table"
            value={name}
            loading={mDb_P_uwModels.isPending}
            onNonDatabaseChange={customExpenseIndex != null ? handleNameChange : undefined}
         />
      </div>
   );
};

const Cell = (p: TExpenseData & { isMonthly?: boolean; modelIndex?: number }) => {
   const { monthly = 0, hcvModelOverrideKey, override = 0, isMonthly } = p;
   const { customExpenseIndex, modelIndex, customModelOverrideKey } = p;
   const Value = isMonthly ? Number(monthly) : Number(monthly) * 12;
   const Override = isMonthly ? override : override * 12;

   const qPVi_Property = useQPVi_Property();
   const { pid = "", underwriting_models } = qPVi_Property.data?.property || {};

   const isCustomModel = modelIndex != null && !!underwriting_models?.length;
   const isCustomExpense = customExpenseIndex != null;

   const db_P = db("sos_properties", [{ column: "pid", id: pid }], qPVi_Property.refetch);
   const mDb_P = useMDatabase_UpdateValue(hcvModelOverrideKey ? db_P(hcvModelOverrideKey) : undefined);
   const mDb_P_uwModels = useMDb_P_UpdateUwModels({ pid });

   const handleExpenseChange = (v: string) => {
      const value = isMonthly ? +v : +v / 12;
      if (!isCustomModel && hcvModelOverrideKey) mDb_P.mutate({ value });
      if (isCustomModel && isCustomExpense) {
         const newModel = {
            ...underwriting_models[modelIndex],
            expenses: underwriting_models[modelIndex].expenses?.map((e, i) =>
               i === customExpenseIndex ? { ...e, monthly: value } : e
            ),
         };
         mDb_P_uwModels.mutate({
            value: JSON.stringify(underwriting_models.map((m, i) => (i === modelIndex ? newModel : m))),
         });
      }
      if (isCustomModel && !isCustomExpense && customModelOverrideKey) {
         const newModel = {
            ...underwriting_models[modelIndex],
            overrides: { ...underwriting_models[modelIndex].overrides, [customModelOverrideKey]: value },
         };
         mDb_P_uwModels.mutate({
            value: JSON.stringify(underwriting_models.map((m, i) => (i === modelIndex ? newModel : m))),
         });
      }
   };
   return (
      <div className={cx("cellC")}>
         <PViDataPoint
            type="only-value"
            value={Override || Value}
            overridden={Value}
            format="currency"
            onNonDatabaseChange={handleExpenseChange}
            loading={mDb_P_uwModels.isPending || mDb_P.isPending}
         />
      </div>
   );
};
