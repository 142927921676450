import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { usePViContext } from "../../../../contexts/PViContext";
import { useOffersContext } from "../../../../contexts/OffersContext";

export const useMConvo_CreateThread = ({ onSuccess, onError }: TUseMConvo_CreateThreadProps) => {
   const { sosAxios } = useSosAxios();

   const { pid } = usePViContext().pviState;

   const { lid } = useOffersContext().offersState;

   return useMutation({
      onSuccess,
      onError,
      mutationFn: (body: createThreadParams) =>
         sosAxios
            .post(`/conversations/threads/`, { ...body, lid, pid })
            .then<TConversations_CreateThread_Response>(({ data }) => data),
   });
};

type createThreadParams = { title: string; content: string; tags: string[] };

type TUseMConvo_CreateThreadProps = {
   onSuccess: (data: TConversations_CreateThread_Response) => void;
   onError: (message: string) => void;
};

export type TConversations_CreateThread_Response = { tid: number };
