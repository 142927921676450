import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "./../../../axios/useSosAxios";
import { useOffersContext } from "../../../../contexts/OffersContext";
import { SoS_GetTermTemplateById_TermTemplate_Data_Field } from "../../queries/termTemplate/useQOffers_GetTermTemplateById";

export const useMOffers_Leads_UpdateLead = ({ onSuccess, onError }: TUseMOffers_Leads_UpdateLeadProps) => {
   const { sosAxios } = useSosAxios();
   const { offersState } = useOffersContext();
   const { leadTermTemplateData, lid } = offersState;

   return useMutation({
      onSuccess,
      onError,
      mutationFn: ({ status }: Partial<TUseMOffers_Leads_UpdateLeadMutate>) => {
         const updateLeadBody: Partial<TUseMOffers_Leads_UpdateLeadMutate> = { terms: leadTermTemplateData };
         if (status) updateLeadBody.status = status;
         return sosAxios
            .patch(`/offers/leads/${lid}`, {
               ...updateLeadBody,
            })
            .then<SoS_Offers_UpdateLead_Response>(({ data }) => data);
      },
   });
};

type TUseMOffers_Leads_UpdateLeadProps = {
   onSuccess: (data: SoS_Offers_UpdateLead_Response) => void;
   onError: (message: string) => void;
};

type SoS_Offers_UpdateLead_Terms = SoS_GetTermTemplateById_TermTemplate_Data_Field;

type TUseMOffers_Leads_UpdateLeadMutate = {
   status?: string;
   terms?: SoS_Offers_UpdateLead_Terms;
};

export type SoS_Offers_UpdateLead_Response = { message: string };
