import classNames from "classnames/bind";
import styles from "./SoSDataPoint.module.scss";
import { SHu_js_currencyFormat, SHu_js_percentageFormat } from "@simplyhomes/utils";
import { useEffect, useState } from "react";
import {
   useMDatabase_UpdateValue,
   useMDatabase_UpdateValue_Params,
} from "../../hooks/database/mutations/useMDatabase_UpdateValue";
import { useClickOutside } from "../../hooks/react/useClickOutside";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { SHrSpinner } from "@simplyhomes/react";
import { SoSInputSelect, TSoSInputSelect_option } from "../common/SoSInputSelect/SoSInputSelect";
import { SoSCheckbox } from "../common/SoSCheckbox/SoSCheckbox";
import { SoS_Fields_GetFieldsHistory_Query } from "../../hooks/fields/queries/useQSOS_FieldsHistory";
import { usePViContext } from "../../contexts/PViContext";
const cx = classNames.bind(styles);

export const SoSDataPoint = (p: SoSDataPoint_Params) => {
   const { title, value, additionalInfor, type, format, icon, displayValue, database, inputType, ...p1 } = p;
   const { isAdd, isMocked, loading, overridden, history, ...p2 } = p1;
   const { prefix, affix, options, options_isMulti, onOptionSelect, buttons, onNonDatabaseChange } = p2;
   const [isEditing, setIsEditing] = useState(false);
   const [internalValue, setInternalValue] = useState(value);

   const { pviOverwrite } = usePViContext();

   useEffect(() => {
      setInternalValue(value);
   }, [value]);

   const formattedValue = displayValue
      ? displayValue
      : Array.isArray(value)
      ? `${value.length} Selected`
      : typeof value === "boolean"
      ? value
         ? "Yes"
         : "No"
      : format === "currency"
      ? SHu_js_currencyFormat(value)
      : format === "percentage"
      ? SHu_js_percentageFormat(value)
      : value || "N/A";
   const Icon = icon ? icons[icon] : null;

   const { ref: clickOutSideRef } = useClickOutside({
      onClickOutside: () => {
         setInternalValue(value);
         setIsEditing(false);
      },
   });
   const onComplete = () => {
      database?.onComplete && database?.onComplete(true);
      setIsEditing(false);
   };
   const useMDatabase_UpdateValueParams = database ? { ...database, onComplete } : undefined;
   const mDbUpdateValue = useMDatabase_UpdateValue(useMDatabase_UpdateValueParams);

   const isDifferentValue = internalValue !== value;

   const isOverridden = overridden != null && value != null && overridden.toString() !== value.toString();
   const formattedOverridden = !isOverridden
      ? null
      : format === "currency"
      ? SHu_js_currencyFormat(overridden)
      : format === "percentage"
      ? SHu_js_percentageFormat(overridden)
      : overridden;
   const allowEdit = onNonDatabaseChange != null || onOptionSelect != null || database !== undefined;

   const handleEnableEditing = () => allowEdit && setIsEditing(true);

   const handleTextValue = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!isDifferentValue || internalValue == null || !allowEdit || mDbUpdateValue.isMutating) return;
      if (useMDatabase_UpdateValueParams) mDbUpdateValue.mutate({ value: internalValue });
      if (onNonDatabaseChange && typeof internalValue === "string") onNonDatabaseChange(internalValue);
      setIsEditing(false);
   };
   const handleSingleOptionValue = (o: TSoSInputSelect_option) => {
      if (onOptionSelect) onOptionSelect([o]);
      if (useMDatabase_UpdateValueParams) mDbUpdateValue.mutate({ value: o.value });
      // mDbUpdateValue.mutate({ value: Array.isArray(o) ? o.map((o) => o.value) : o.value });
      setIsEditing(false);
   };
   const handleMultiOptionValue = (o: TSoSInputSelect_option[]) => {
      if (onOptionSelect) onOptionSelect(o);
      if (useMDatabase_UpdateValueParams) mDbUpdateValue.mutate({ value: o.map((o) => o.value) });
      setIsEditing(false);
   };

   const handleBoolValue = (b: boolean) => {
      if (useMDatabase_UpdateValueParams) mDbUpdateValue.mutate({ value: b });
      setIsEditing(false);
   };

   return (
      <div className={cx("contentC", `type-${type}`, { isMocked })} ref={clickOutSideRef}>
         <div className={cx("titleC")}>
            <span>{title}</span>
            {history && (
               <button
                  onClick={() =>
                     pviOverwrite({
                        isShowChangesHistoryModal: true,
                        history,
                        fieldInfoCurrentvalue: formattedValue,
                     })
                  }
               >
                  <div className={cx("img-icon")}>
                     <img className={cx({})} src={CF_CDN_URL("/assets/offer/info_black.svg")} alt="" />
                  </div>
               </button>
            )}
            {isAdd && (
               <div className={cx("img-icon")}>
                  <img className={cx({})} src={CF_CDN_URL("/assets/offer/add_black.svg")} alt="" />
               </div>
            )}
            {buttons &&
               buttons.map((b, i) => (
                  <button key={i} type="button" className={cx("button")} onClick={b.onClick} disabled={b.loading}>
                     {b.loading ? <SHrSpinner /> : b.title}
                  </button>
               ))}
         </div>
         <div className={cx(`valueC`, { "no-padding": isEditing })} onDoubleClick={handleEnableEditing}>
            {Icon && <img title={title} src={Icon} alt={title} />}
            {isEditing && options && !options_isMulti ? (
               <SoSInputSelect
                  customStyle="datapoint"
                  isMulti={!!options_isMulti}
                  value={value?.toString() || ""}
                  onChange={handleSingleOptionValue}
                  options={options}
               />
            ) : isEditing && options && options_isMulti && Array.isArray(value) ? (
               <SoSInputSelect
                  customStyle="datapoint"
                  isMulti={!!options_isMulti}
                  value={value.map((v) => v.toString())}
                  onChange={handleMultiOptionValue}
                  options={options}
               />
            ) : isEditing && typeof value === "boolean" ? (
               <SoSCheckbox value={value} onChange={handleBoolValue} />
            ) : isEditing ? (
               <form className={cx("input_select")} onSubmit={handleTextValue}>
                  <input
                     autoFocus
                     title={title}
                     type={inputType || "text"}
                     value={internalValue?.toString() || ""}
                     onChange={(e) => setInternalValue(e.target.value)}
                  />
                  <button
                     type="submit"
                     className={cx("img-icon", { enabled: isDifferentValue && !mDbUpdateValue.isMutating })}
                  >
                     <img src={CF_CDN_URL("/assets/property_validator/check_circle_neutral_200.svg")} alt="save" />
                  </button>
               </form>
            ) : (
               <div className={cx("value")}>
                  {prefix && <span>{prefix} </span>}
                  {isOverridden && (
                     <>
                        <span className={cx(`overridden`)}>{formattedOverridden}</span>
                        &nbsp;
                     </>
                  )}

                  <span title={formattedValue.toString()}>
                     {formattedValue}&nbsp;
                     {additionalInfor}
                  </span>
                  {affix && <span> {affix}</span>}
               </div>
            )}
            {loading || mDbUpdateValue.isMutating ? (
               <SHrSpinner />
            ) : (
               allowEdit &&
               !isEditing && (
                  <button type="button" className={cx("edit-icon")}>
                     <img
                        onClick={handleEnableEditing}
                        src={CF_CDN_URL("/assets/property_validator/edit_black.svg")}
                        alt="edit"
                     />
                  </button>
               )
            )}
         </div>
      </div>
   );
};
const icons = {
   sqft: CF_CDN_URL("/assets/property_validator/square_foot_black.svg"),
   yearbuilt: CF_CDN_URL("/assets/construction_black.svg"),
   propertyType: CF_CDN_URL("/assets/nav_home.svg"),
   neighborhood: CF_CDN_URL("/assets/property_validator/holiday_village_black.svg"),
   housing_authority: CF_CDN_URL("/assets/property_validator/housing_authority_black.svg"),
   source: CF_CDN_URL("/assets/property_validator/source_black.svg"),
   oclock: CF_CDN_URL("/assets/property_validator/watch_later_black.svg"),
   occupied: CF_CDN_URL("/assets/property_validator/sensor_occupied_black.svg"),
   contingent: CF_CDN_URL("/assets/property_validator/person_search_black.svg"),
   bedroom: CF_CDN_URL("/assets/property_validator/bedroom_parent_black.svg"),
   bathroom: CF_CDN_URL("/assets/property_validator/bathroom.svg"),
   local_activity: CF_CDN_URL("/assets/property_validator/local_activity_black.svg"),
   store: CF_CDN_URL("/assets/property_validator/store_black.svg"),
};
export type SoSDataPoint_Params_Format = "currency" | "percentage";
export type SoSDataPoint_Params_Button = {
   title: string;
   onClick: () => void;
   loading?: boolean;
};
type type = "bold-value" | "bold-value-table" | "only-value-table" | "bold-title" | "only-value" | "bold-only-value";
export type SoSDataPoint_Params = {
   prefix?: string;
   affix?: string;
   title?: string;
   inputType?: "text" | "number";
   type: type;
   additionalInfor?: string;
   displayValue?: string;
   isAdd?: boolean;
   isMocked?: boolean;
   format?: SoSDataPoint_Params_Format;
   icon?: keyof typeof icons;
   auditKey?: string; //TODO REMOVE THIS LOGIC
   options?: TSoSInputSelect_option[] | string[];
   options_isMulti?: boolean;
   isMapToLabel?: boolean;
   onOptionSelect?: (o: TSoSInputSelect_option[]) => void;
   buttons?: SoSDataPoint_Params_Button[];
   loading?: boolean;
   database?: useMDatabase_UpdateValue_Params;
   onNonDatabaseChange?: (v: string) => void;
   overridden?: string | number | undefined | null;
   value: string | number | undefined | null | boolean | (string | number)[];
   history?: Required<Pick<SoS_Fields_GetFieldsHistory_Query, "column" | "table" | "record_id">>;
};
