import { createContext, useContext, useReducer } from "react";
import { Database_Table } from "../hooks/database/mutations/utils";

class PViState {
   showDetailsModal: boolean = false;
   pid: string = "";
   locked: boolean = false;
   excludedPropertyIds: string[] = [];
   isRunStepJoyRideInPropertyBrowser: boolean = false;
   isRunStepJoyRideInPropertyDetails: boolean = false;
   isShowChangesHistoryModal: boolean = false;
   fieldInfoCurrentvalue: string | number | undefined | null | boolean | (string | number)[] = null;
   history: { column?: string; record_id: string; table: Database_Table } | null = null;
}

type TPVUpAction = { type: ""; payload: {} } | { overwrite: TPViOverwrite };
type TPViOverwrite = Partial<PViState>;
export const PVContext = createContext<{ pviState: PViState; pviDispatch: React.Dispatch<TPVUpAction> }>({
   pviState: new PViState(),
   pviDispatch: () => {},
});

const pviReducer = (state: PViState, action: TPVUpAction): PViState => {
   if ("overwrite" in action) {
      return { ...state, ...action.overwrite };
   }
   switch (action.type) {
      default:
         break;
   }
   return state;
};

export const PViProvider = ({ children }: { children: React.ReactNode }) => {
   const [pviState, pviDispatch] = useReducer(pviReducer, new PViState());

   return <PVContext.Provider value={{ pviState, pviDispatch }}>{children}</PVContext.Provider>;
};

export const usePViContext = () => {
   const context = useContext(PVContext);
   const pviOverwrite = (overwrite: TPViOverwrite) => context.pviDispatch({ overwrite });
   return { ...context, pviOverwrite };
};
