import ReactSelect, { components } from "react-select";
import styles from "./SoSInputSelect.module.scss";
import { ComponentProps, useMemo, useState } from "react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
export const SoSInputSelect = (p: TSoSInputSelectProps) => {
   const {
      SelectProps,
      customOptions,
      customCreateMessage = "",
      isMulti,
      options,
      allowCreate,
      allowUpdate,
      value,
      error,
      onChange,
      onInputChange,
   } = p;
   const { customStyle, isLoading, className, placeholder, title, dropdownIndicator = "default" } = p;
   const { containerProps } = p;

   const [showMenu, setShowMenu] = useState(false);

   const Options = useMemo(() => options.map((o) => (typeof o === "string" ? { label: o, value: o } : o)), [options]);

   const Value = useMemo(
      () =>
         isMulti
            ? value.map((v) =>
                 typeof v === "string"
                    ? {
                         label: Options.find((op) => op.value === v)?.label || v,
                         value: v,
                      }
                    : v
              )
            : typeof value === "string"
            ? { label: value, value }
            : value,
      [Options, isMulti, value]
   );

   const allOptions: any[] = [...(Options || []), ...(customOptions || [])];

   const hasOptions = allOptions.length;
   const [internalValue, setInternalValue] = useState("");
   const handleInputChange = (value: string) => {
      setInternalValue(value);
      onInputChange && onInputChange(value);
   };
   const createCustomOption: TSoSInputSelect_customOption | null = useMemo(() => {
      if (allowCreate && !!internalValue) {
         return {
            label: allowCreate.label
               ? allowCreate.label(internalValue)
               : `Create ${customCreateMessage} "${internalValue}"`,
            cb: ({ label }) => allowCreate.cb({ label, value: internalValue }),
         };
      }
      return null;
   }, [allowCreate, customCreateMessage, internalValue]);

   const updateCustomOption: TSoSInputSelect_customOption | null = useMemo(() => {
      if (allowUpdate && !!internalValue) {
         return {
            label: allowUpdate.label ? allowUpdate.label(internalValue) : `Update to "${internalValue}"`,
            cb: ({ label }) => allowUpdate.cb({ label, value: internalValue }),
         };
      }
      return null;
   }, [allowUpdate, internalValue]);

   const CustomOptions = useMemo(() => {
      const options = [...(customOptions || [])];
      if (updateCustomOption) options.unshift(updateCustomOption);
      if (createCustomOption) options.unshift(createCustomOption);
      return options;
   }, [customOptions, createCustomOption, updateCustomOption]);
   const MenuList = ({
      customOptions,
      ...props
   }: ComponentProps<typeof components.MenuList> & { customOptions?: TSoSInputSelect_customOption[] }) => (
      <components.MenuList {...props} className={styles.menuList}>
         {customOptions?.map(({ label, cb }) => (
            <div
               key={label}
               onClick={() => {
                  cb({ label });
                  setInternalValue("");
                  setShowMenu(false);
               }}
               className={`${styles.customOption}`}
            >
               {label}
            </div>
         ))}
         {props.children}
      </components.MenuList>
   );
   return (
      <div
         {...containerProps}
         className={`${containerProps?.className} ${styles.contentC} ${styles[`style-${customStyle}`]}`}
      >
         {title && <span className={`${styles.title}`}>{title}</span>}
         <ReactSelect
            {...SelectProps}
            placeholder={placeholder}
            isLoading={isLoading}
            menuIsOpen={isLoading === true ? false : showMenu}
            onMenuOpen={() => setShowMenu(true)}
            onMenuClose={() => setShowMenu(false)}
            closeMenuOnSelect
            options={Options}
            isMulti={isMulti}
            value={Value}
            onChange={(e: any) => onChange(e)}
            className={`${className} ${styles.select}`}
            onInputChange={handleInputChange}
            components={{
               Control,
               // Control: (p) => <Control {...p} {...controlProps} />,
               ValueContainer,
               Input,
               IndicatorsContainer,
               LoadingIndicator,
               IndicatorSeparator: () => null,
               Menu: (p) => (hasOptions ? Menu({ ...p }) : null),
               MenuList: (p) => (hasOptions ? MenuList({ ...p, customOptions: CustomOptions }) : null),
               DropdownIndicator: (p) => DropdownIndicator({ ...p, dropdownIndicator }),
               // ...DropdownSearchIndicator,
            }}
         ></ReactSelect>
         {error && <p className={`${className} ${styles.error}`}>{error}</p>}
      </div>
   );
};
export type TSoSInputSelect_option = { label: string; value: string; sub?: string };
export type TSoSInputSelect_customOption = { label: string; cb: (p: { label: string }) => void };
type TSoSInputSelect_DropdownIndicator = "default" | "search" | null;
export type TSoSInputSelectProps = {
   customStyle?: "question-editor" | "datapoint";
   title?: string;
   error?: string;
   placeholder?: string;
   customCreateMessage?: string;
   className?: string;
   SelectProps?: Omit<SelectProps, "value" | "onChange" | "isMulti" | "options">;
   options: (TSoSInputSelect_option | string)[];
   customOptions?: TSoSInputSelect_customOption[];
   isLoading?: boolean;
   dropdownIndicator?: TSoSInputSelect_DropdownIndicator;
   containerProps?: React.HTMLAttributes<HTMLDivElement>;
   allowCreate?: { label?: (inputVal: string) => string; cb: (p: { label: string; value: string }) => void };
   allowUpdate?: { label?: (inputVal: string) => string; cb: (p: { label: string; value: string }) => void };
   onInputChange?: (value: string) => void;
} & (
   | { isMulti: true; value: (TSoSInputSelect_option | string)[]; onChange: (value: TSoSInputSelect_option[]) => void }
   | { isMulti: false; value: TSoSInputSelect_option | string; onChange: (value: TSoSInputSelect_option) => void }
);

type SelectProps = ComponentProps<typeof ReactSelect>;

const Control = (props: ComponentProps<typeof components.Control>) => (
   <components.Control {...props} className={`${props.className} ${styles.control}`} />
);

const ValueContainer = (props: ComponentProps<typeof components.ValueContainer>) => (
   <components.ValueContainer {...props} className={styles.valueContainer} />
);

const Input = (props: ComponentProps<typeof components.Input>) => (
   <components.Input {...props} className={styles.input} />
);

const DropdownIndicator = (
   props: ComponentProps<typeof components.DropdownIndicator> & { dropdownIndicator: TSoSInputSelect_DropdownIndicator }
) =>
   props.dropdownIndicator === null ? null : (
      <components.DropdownIndicator {...props} className={styles.dropdownIndicator}>
         {props.dropdownIndicator === "search" && <img src={CF_CDN_URL("/assets/search.svg")} alt="" />}
      </components.DropdownIndicator>
   );

const IndicatorsContainer = (props: ComponentProps<typeof components.IndicatorsContainer>) => (
   <components.IndicatorsContainer {...props} className={styles.indicatorsContainer} />
);
const LoadingIndicator = (props: ComponentProps<typeof components.LoadingIndicator>) => (
   <components.LoadingIndicator {...props} className={styles.loadingIndicator} />
);

const Menu = (props: ComponentProps<typeof components.Menu>) => <components.Menu {...props} className={styles.menu} />;
