import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import styles from "./SoSInputTitle.module.scss";
import { Mention, MentionsInput } from "react-mentions";

export const SoSInputTitle = (p: TSoSInputTitleProps) => {
   const {
      handleValue,
      title,
      comment,
      data,
      type,
      value,
      inputProps,
      placeholder,
      error,
      icon,
      labelProps,
      isNotChanged,
   } = p;
   return (
      <label {...labelProps} className={`${labelProps?.className} ${styles[`style-${type}`]}`}>
         {title && isNotChanged ? <span>{title}</span> : <b>{title}</b>}
         {isNotChanged ? (
            <div>{value}</div>
         ) : (
            <div className={`${error && styles.error}`}>
               {type === "mention" ? (
                  <MentionsInput
                     placeholder="Add Comment. Use '@' for mention and '&' for emojis"
                     value={comment}
                     onChange={(e) => {
                        if (handleValue) handleValue(e.target.value);
                     }}
                  >
                     <Mention data={data || []} trigger="@" />
                  </MentionsInput>
               ) : (
                  <>
                     <input
                        {...inputProps}
                        placeholder={placeholder}
                        type={type}
                        value={value}
                        onChange={(e) => {
                           if (handleValue) handleValue(e.target.value);
                        }}
                     />
                     {icon && (
                        <div>
                           <img src={icons[icon]} alt="icon" />
                        </div>
                     )}
                  </>
               )}
            </div>
         )}

         {error && <span className={styles.error}>{error}</span>}
      </label>
   );
};
const icons = {
   search: CF_CDN_URL("/assets/search.svg"),
};

type TDataMention = {
   id: string;
   display: string;
};

export type TSoSInputTitleProps = {
   type: "text" | "number" | "mention" | "date";
   data?: TDataMention[];
   comment?: string;
   isNotChanged?: boolean;
   title?: string;
   value: string | number;
   handleValue?: (value: string) => void;
   icon?: keyof typeof icons;
   error?: string;
   placeholder?: string;
   inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
   labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
};
