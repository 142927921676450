import classNames from "classnames/bind";
import styles from "./OffersLeadsList.module.scss";

import SoSFilter from "../../../components/common/SoSFilter/SoSFilter";
import { OffersLeadCard } from "../OffersLeadCard/OffersLeadCard";
import { OffersSettingsTerm } from "../OffersSettingsTerm/OffersSettingsTerm";
import { SHrPopup, SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { useSoSOptions_Offers_Leads_Statuses } from "../../../hooks/queries/options/useQSoS_Options";
import { useOffersQueryParams } from "../../../hooks/offers/hooks/useOffersQueryParams";
import { useQOffers_GetLeads } from "../../../hooks/offers/queries/leads/useQOffers_GetLeads";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { OffersFilterByPeopleModal } from "../OffersFilterByPeopleModal/OffersFilterByPeopleModal";
import { useState } from "react";
const cx = classNames.bind(styles);

export const OffersLeadsList = () => {
   const qOfferLeads = useQOffers_GetLeads();
   const qpOfferLeads = useOffersQueryParams();

   const { status, assignee, cids, setStatus } = qpOfferLeads;

   const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);

   const qLeadStatusesOptions = useSoSOptions_Offers_Leads_Statuses().data?.options || [];

   const statusSelectedActive = qLeadStatusesOptions?.[0] || { label: "Active", value: "active" };
   const statusSelected = qLeadStatusesOptions.find((o) => o.label === status) || statusSelectedActive;

   const leadWithFilters = qOfferLeads.data?.leads.length || 0;

   const people = (assignee.length || 0) + (cids.length || 0);

   return (
      <div className={cx("leftC")}>
         <OffersSettingsTerm />
         <div className={cx("leftBodyC")}>
            <div className={cx("filterTopC",'_jr_offersLeadsFilters')}>
               <SoSFilter
                  title={`${statusSelected.label} (${leadWithFilters})`}
                  isBoldTitle={!!statusSelected}
                  isMulti={false}
                  value={statusSelected}
                  onChange={(vs) => (vs.value === status ? setStatus("") : setStatus(vs.label))}
                  options={qLeadStatusesOptions}
               />
               <div className={cx("filterAssignee")}>
                  <SHrSosButton type="outlined" buttonProps={{ onClick: () => setIsOpenFilterModal(true) }}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/offer/person_search.svg")} alt="" />
                     </div>
                     People ({people})
                  </SHrSosButton>
               </div>
            </div>

            <div className={cx("leadsListC",'_jr_offersLeadsList')}>
               {qOfferLeads.isLoading && qOfferLeads.isFetching ? (
                  <div className={cx("spinnerC")}>
                     <SHrSpinner />
                     <span>Loading Leads...</span>
                  </div>
               ) : qOfferLeads.data?.leads.length ? (
                  qOfferLeads.data?.leads.map((lead, i) => <OffersLeadCard key={i} lid={lead.lid} />)
               ) : (
                  <div className={cx("noDataC")}>No lead matching filter!</div>
               )}
            </div>
         </div>

         <SHrPopup show={isOpenFilterModal}>
            <OffersFilterByPeopleModal onClose={() => setIsOpenFilterModal(false)} />
         </SHrPopup>
      </div>
   );
};
