import { ESoS_LeadStatus } from "@simplyhomes/utils";
import { useQueryParams } from "../../react/useQueryParams";

export const useOffersQueryParams = () => {
   const { useSingleQueryParamsState, useMultiQueryParamsState } = useQueryParams();
   const [status, setStatus] = useSingleQueryParamsState("status", ESoS_LeadStatus.ACTIVE);
   const [assignee, setAssigneeIds] = useMultiQueryParamsState("assignee", []);
   const [cids, setContactIds] = useMultiQueryParamsState("cids", []);

   const filters = { status, assignee, cids };
   return {
      filters,
      ...filters,
      setStatus,
      setAssigneeIds,
      setContactIds,
   };
};
