import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useQConvo_Deals } from "../../queries/sellerOutreach/useQConvo_Deals";

export const useMConvo_CreateDeal = ({ sid }: TDealsProps) => {
   const { sosAxios } = useSosAxios();
   const { toastDispatch } = useToastContext();

   const qConvoGetDeals = useQConvo_Deals({ sid });

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Create new comment is successful!",
            },
         });
         qConvoGetDeals.refetch();
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Create comment lead is failed!",
            },
         }),
      mutationFn: () =>
         sosAxios
            .post(`/conversations/seller-outreach/deals`, { sid })
            .then<TConversations_CreateDeal_Reponse>(({ data }) => data),
   });
};
type TDealsProps = {
   sid: number;
};
export type TConversations_CreateDeal_Reponse = {
   message: string;
};
