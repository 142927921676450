import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQPVi_PropertiesBySource = ({ sid }: TUseQPVi_PropertiesBySource) => {
   const { sosAxios } = useSosAxios();

   return useQuery({
      enabled: !!sid,
      queryKey: [`useQPVi_PropertiesBySource`, sid],
      queryFn: () =>
         sosAxios
            .get(`/property-viability/properties/sources/${sid}`)
            .then<TPVi_GetPropertiesBySource_Response>(({ data }) => data),
   });
};

type TUseQPVi_PropertiesBySource = {
   sid: number;
};

export class TPVi_GetPropertiesBySource_Property_Unit {
   uid!: number;
   beds!: number;
   baths!: number;
}

export class TPVi_GetPropertiesBySource_Property {
   pid!: string;
   address_one_line!: string;
   street_view_url_150!: string;
   asking_price!: number;
   sqft!: number;
   units!: TPVi_GetPropertiesBySource_Property_Unit[];
}

export class TPVi_GetPropertiesBySource_Response {
   properties!: TPVi_GetPropertiesBySource_Property[];
}
