import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { SoS_TermTemplate } from "../../queries/termTemplate/useQOffers_GetTermTemplateById";

export const useMTT_UpdateTermTemplate = ({ onSuccess, onError }: TUseMTT_UpdateTermTemplateProps) => {
   const { sosAxios } = useSosAxios();

   return useMutation({
      onSuccess,
      onError,
      mutationFn: ({ ttid, name, termTemplateData }: TUseMTT_UpdateTermTemplateMutate) =>
         sosAxios
            .patch(`/offers/term-templates/${ttid}`, {
               ...(name && { name }),
               ...(termTemplateData && { data: termTemplateData }),
            })
            .then<SoS_UpdateTermTemplate_Response>(({ data }) => data),
   });
};

type SoS_UpdateTermTemplate_Response = { message: string };

type TUseMTT_UpdateTermTemplateProps = {
   onSuccess: (data: SoS_UpdateTermTemplate_Response) => void;
   onError: (message: string) => void;
};

type TUseMTT_UpdateTermTemplateMutate = {
   ttid: number;
   name?: string;
   termTemplateData?: SoS_TermTemplate;
};
