import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useQConvo_Threads } from "../../queries/threads/useQConvo_Threads";

export const useMConvo_ToggleThreadSubscription = () => {
   const { sosAxios } = useSosAxios();
   const { toastDispatch } = useToastContext();
   const qGetThreads = useQConvo_Threads();
   return useMutation({
      onSuccess: () => {
         qGetThreads.refetch();
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Subscription successful!",
            },
         });
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Subscription failed!",
            },
         }),
      mutationFn: ({ tid, subscribed }: TSoS_Convo_ThreadSubscription_Props) =>
         sosAxios
            .patch(`/conversations/threads/${tid}/subscriptions`, { subscribed })
            .then<TSoS_Convo_ThreadSubscription_Response>(({ data }) => data),
   });
};

type TSoS_Convo_ThreadSubscription_Props = {
   tid: number;
   subscribed: boolean;
};

// type TUseMConvo_ThreadSubscriptionProps = {
//    onSuccess: (data: TSoS_Convo_ThreadSubscription_Response) => void;
//    onError: (message: string) => void;
// };

type TSoS_Convo_ThreadSubscription_Response = { message: string };
