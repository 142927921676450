import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQSOS_FieldsInfo = ({ column, table }: SoS_Fields_GetFieldInfo_Query) => {
   const { sosAxios } = useSosAxios();

   return useQuery({
      enabled: !!column,
      refetchOnMount: true,
      refetchInterval: 1000 * 60,
      queryKey: ["useQSOS_FieldsInfo", { column, table }],
      queryFn: () =>
         sosAxios
            .get(`/fields/info`, { params: { column, table } })
            .then<SoS_Fields_GetFieldInfo_Response>(({ data }) => data),
   });
};

export type SoS_Fields_GetFieldInfo_Query = {
   table: string;
   column: string;
};

export class SoS_Fields_GetFieldInfo_Info {
   description!: string;
   formula!: string;
   label!: string;
}

export type SoS_Fields_GetFieldInfo_Response = {
   info: SoS_Fields_GetFieldInfo_Info;
};
