import classNames from "classnames/bind";
import styles from "./OffersRelactedContact.module.scss";
import { SHrSosButton } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { ContactTable } from "./ContactTable/ContactTable";

const cx = classNames.bind(styles);

export const OffersRelactedContact = () => {
   return (
      <div className={cx("container")}>
         <div className={cx("addPropertyC")}>
            <span>Related Contacts</span>
            <SHrSosButton
               type="text"
               buttonProps={{
                  className: cx("btn"),
                  onClick: () => {},
               }}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/offer/refresh_info500.svg")} alt="" />
               </div>
               Refresh
            </SHrSosButton>
         </div>

         <div className={cx("tableContainer")}>
            <ContactTable />
         </div>
      </div>
   );
};
