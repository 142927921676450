import { useMutation } from "@tanstack/react-query";

import { useQEContext } from "../../../contexts/QEditorContext";
import { useQQE_Questions } from "../../queries/QEditor/useQQE_Questions";
import { useSosAxios } from "../../axios/useSosAxios";

export const useMQE_UpdateQuestion = () => {
   const { sosAxios } = useSosAxios();
   const qQuestions = useQQE_Questions();
   const { qeState, qeDispatch } = useQEContext();
   const { qIdSelected, qSelected } = qeState;

   return useMutation({
      mutationFn: () =>
         sosAxios.put(`/qeditor/questions/${qIdSelected}`, { question: qSelected }).then<TData>(({ data }) => {
            qQuestions.refetch().then(() => qeDispatch({ type: "select-question", payload: { qId: data.qid } }));
            return data;
         }),
   });
};

type TData = { qid: string };
