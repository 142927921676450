import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useQPVi_Properties } from "../../queries/useQPVi_Properties";
import { useQPVi_Property } from "../../queries/useQPVi_Property";
import { usePViContext } from "../../../../contexts/PViContext";

export const useMPVi_LockEdit = () => {
   const { sosAxios } = useSosAxios();
   const { toastDispatch } = useToastContext();

   const { pid } = usePViContext().pviState;

   const qPVi_Property = useQPVi_Property();
   const qPVi_Properties = useQPVi_Properties();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Change Lock Edit Action is successful!",
            },
         });
         qPVi_Property.refetch();
         qPVi_Properties.refetch();
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Change Lock Edit Action is failed!",
            },
         }),
      mutationFn: ({ locked }: TPVI_LockEdit_Params) =>
         sosAxios
            .patch(`/property-viability/properties/${pid}/lock-edit`, { locked })
            .then<TPVI_LockEdit_Response>(({ data }) => data),
   });
};

export type TPVI_LockEdit_Params = {
   locked: boolean;
};

export type TPVI_LockEdit_Response = {
   message: string;
};
