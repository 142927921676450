import { useRef } from "react";
import classNames from "classnames/bind";
import styles from "./SoSSlider.module.scss";
import { GoogleMapWrapper } from "../GoogleMapWrapper/GoogleMapWrapper";
import { MarkerF } from "@react-google-maps/api";
import { OffersViablePropertyCard } from "../../pages/OffersPage/OffersViablePropertyCard/OffersViablePropertyCard";
import { PViSourcePropertyCard } from "../../pages/PropertyViabilityPage/PViPropertyDetails/PViPropertyDetailsTabs/PViSource/PViSoucePropertyCard/PViSourcePropertyCard";

const cx = classNames.bind(styles);

const SoSSlider = ({ items }: SoSSliderParams) => {
   const scrollRef = useRef<HTMLDivElement>(null);
   const scroll = (direction: "prev" | "next") => {
      if (scrollRef.current)
         scrollRef.current.scrollBy({ left: 800 * (direction === "prev" ? -1 : 1), behavior: "smooth" });
   };
   return (
      <div className={styles.slidesC}>
         <NavButton type="prev" onClick={() => scroll("prev")} />
         <div
            ref={scrollRef}
            className={cx("slides", {
               "source-property-card": items.some((item) => item.type === "source-property-card"),
            })}
         >
            {items.map((item, i) => (
               <div key={i}>
                  <div>
                     {item.type === "image" && <img src={item.src} alt="media-image" />}
                     {item.type === "map" && (
                        <GoogleMapWrapper center={item.coordinate} zoom={10}>
                           <MarkerF position={item.coordinate} />
                        </GoogleMapWrapper>
                     )}
                     {item.type === "offer-property-card" && <OffersViablePropertyCard pid={item.pid} key={item.pid} />}
                     {item.type === "source-property-card" && (
                        <PViSourcePropertyCard pid={item.pid} sid={item.sid} key={item.pid} />
                     )}
                  </div>
                  {item.type === "offer-property-card" && (
                     <div className={styles.dividerContainer}>
                        <hr className={styles.divider} />
                     </div>
                  )}
               </div>
            ))}
         </div>
         <NavButton type="next" onClick={() => scroll("next")} />
      </div>
   );
};

export default SoSSlider;

type SoSSliderParams = {
   items: SoSSlider_Item[];
};
export type SoSSlider_Item =
   | SoSSlider_Item_Image
   | SoSSlider_Item_Map
   | SoSSlider_Item_OfferPropertyCard
   | SoSSlider_Item_PViSourcePropertyCard;
type SoSSlider_Item_Image = { type: "image"; src: string };
type SoSSlider_Item_Map = { type: "map"; coordinate: { lat: number; lng: number } };
type SoSSlider_Item_OfferPropertyCard = { type: "offer-property-card"; pid: string };
type SoSSlider_Item_PViSourcePropertyCard = {
   type: "source-property-card";
   pid: string;
   sid: number;
};
type navButtonProps = {
   type: "prev" | "next";
   onClick: () => void;
};
const NavButton = ({ onClick, type }: navButtonProps) => {
   return (
      <button onClick={onClick} type="button" title={type} className={`${styles[`nav-${type}`]}`}>
         {type === "prev" ? (
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
               <path
                  transform="rotate(90 12 12)"
                  d="M18.846 9.867a.478.478 0 0 0-.015-.716.625.625 0 0 0-.799-.013l-5.611 5.027a.778.778 0 0 1-1.048-.009L5.968 9.138a.625.625 0 0 0-.8.013.478.478 0 0 0-.014.716l6.223 5.664c.294.267.74.27 1.038.008l6.43-5.672z"
                  fill="#1A2D3D"
               />
            </svg>
         ) : (
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
               <path
                  transform="rotate(-90 12 12)"
                  d="M18.846 9.867a.478.478 0 0 0-.015-.716.625.625 0 0 0-.799-.013l-5.611 5.027a.778.778 0 0 1-1.048-.009L5.968 9.138a.625.625 0 0 0-.8.013.478.478 0 0 0-.014.716l6.223 5.664c.294.267.74.27 1.038.008l6.43-5.672z"
                  fill="#1A2D3D"
               />
            </svg>
         )}
      </button>
   );
};
