import { SHrSosButton } from "@simplyhomes/react";
import SoSSlider, { SoSSlider_Item } from "../../../../components/SoSSlider/SoSSlider";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import classNames from "classnames/bind";
import styles from "./PViMediaSlider.module.scss";
import { useQPVi_Property } from "../../../../hooks/property-viability/queries/useQPVi_Property";

const cx = classNames.bind(styles);

export const PViMediaSlider = () => {
   const { property } = useQPVi_Property().data || {};

   if (!property) return null;

   const { street_view_url_400, lat, lng } = property;

   const items: SoSSlider_Item[] = [];

   items.push({ type: "map", coordinate: { lat, lng } });

   if (street_view_url_400) items.push({ type: "image", src: street_view_url_400 });

   return (
      <div className={cx("mediaC", "_jr_pviMediaSlider")}>
         <SoSSlider items={items}></SoSSlider>
         <div className={cx("addPhotosC")}>
            <SHrSosButton buttonProps={{ className: cx("btnAdd") }} type="outlined">
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/plus_white.svg")} alt="" />
               </div>
               <span>Add Photos</span>
            </SHrSosButton>
         </div>
      </div>
   );
};
// const items = [
//    "https://www.simplyhomes.com/hs-fs/hubfs/family-experience_800w.jpg?width=500&name=family-experience_800w.jpg",
//    "https://www.simplyhomes.com/hubfs/Moving_scene_04_800w.png",
//    "https://www.simplyhomes.com/hubfs/Offer-engine-warm_with_Simply_Sam_slightcrop.png",
//    "https://www.simplyhomes.com/hubfs/family%20transparent.png",
//    "https://www.simplyhomes.com/hubfs/stocksy-3049078.png",
// ];
