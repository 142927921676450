import { useQPVi_Property } from "../../hooks/property-viability/queries/useQPVi_Property";
import { SoSDataPoint, SoSDataPoint_Params } from "../SoSDataPoint/SoSDataPoint";
// import styles from "./PViDataPoint.module.scss";

export const PViDataPoint = (p: SoSDataPoint_Params) => {
   const qPVi_Property = useQPVi_Property();

   const property = qPVi_Property.data?.property;

   if (!property) return null;

   const { locked } = property;

   return (
      <SoSDataPoint
         {...p}
         {...(locked
            ? {
                 database: undefined,
                 onOptionSelect: undefined,
                 onNonDatabaseChange: undefined,
                 buttons: [],
              }
            : {})}
      />
   );
};
