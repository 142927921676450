import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useAppContext } from "../../../contexts/AppContext";

export const useQSOS_Profile = () => {
   const { sosAxios } = useSosAxios();
   const { appState, appDispatch } = useAppContext();

   const { credential } = appState;

   return useQuery({
      enabled: !!credential,
      queryKey: ["useQSOS_Profile", credential],
      retry: false,
      queryFn: () =>
         sosAxios
            .get("profile")
            .then<TSoS_GetUserProfile_Respone>(({ data }: { data: TSoS_GetUserProfile_Respone }) => {
               appDispatch({ overwrite: { currentUser: data.profile } });
               return data;
            }),
   });
};

export type TSoS_GetUserProfile = {
   email: string;
   name: string;
   picture: string;
   roles: string[];
   subscribed_apps?: string[];
};

export type TSoS_GetUserProfile_Respone = {
   profile: TSoS_GetUserProfile;
};
