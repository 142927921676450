import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useOffersQueryParams } from "../../hooks/useOffersQueryParams";

export const useQOffers_GetLeads = () => {
   const { sosAxios } = useSosAxios();
   const { filters } = useOffersQueryParams();

   const query = useQuery({
      queryKey: [`useQOffers_GetLeads`, filters.assignee, filters.cids, filters.status],
      staleTime: 500,
      queryFn: () =>
         sosAxios.get(`/offers/leads`, { params: { ...filters } }).then<SoS_Offers_GetLeads_Response>(({ data }) => {
            const leads = data.leads;
            const leadsMap = leads.reduce(
               (acc: Record<string, SoS_Offers_GetAllLeads_Lead>, lead: SoS_Offers_GetAllLeads_Lead) => {
                  acc[lead.lid] = lead;
                  return acc;
               },
               {} as Record<string, SoS_Offers_GetAllLeads_Lead>
            );

            return {
               leads,
               leadsMap,
               leadIds: leads.map((lead: SoS_Offers_GetLeads_Response) => lead.leadIds),
            };
         }),
   });
   return query;
};

type SoS_Offers_GetLeads_Response = {
   leads: SoS_Offers_GetAllLeads_Lead[];
   leadsMap: Record<string, SoS_Offers_GetAllLeads_Lead>;
   leadIds: string[];
};

export type SoS_Offers_GetAllLeads_Lead = {
   lid: number;
   status: string;
   name: string;
   created_at: string;
   sid: number;
   properties_count: number;
   assignee: string[];
   next_actions: string[];
};
