import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";

export const useQConvo_Deals = ({ sid }: TDealsBody) => {
   const { sosAxios } = useSosAxios();
   const query = useQuery({
      enabled: !!sid,
      queryKey: [`useQConvo_Deals`, sid],
      staleTime: 500,
      queryFn: () =>
         sosAxios
            .get(`/conversations/seller-outreach/deals`, { params: { sid } })
            .then<TConversations_GetSourceDeals_Response>(({ data }) => data),
   });
   return query;
};

type TDealsBody = {
   sid?: number;
};

export class TConversations_GetSourceDeals_Contact {
   jobtitle!: string;
   email!: string;
   name!: string;
}

export class TConversations_GetSourceDeals_Deal {
   id!: string;
   createdate!: string;
   dealname!: string;
   dealstage!: string;
   dealowner!: string;
   owneremail!: string;
   pipeline!: string;
   source_source!: string;
   contacts!: TConversations_GetSourceDeals_Contact[];
}

export class TConversations_GetSourceDeals_Property {
   address_one_line!: string;
   street_view_url_150!: string;
   max_offer!: number;
}

export type TConversations_GetSourceDeals_Response = {
   latestDeal: TConversations_GetSourceDeals_Deal;
   otherDeals: TConversations_GetSourceDeals_Deal[];
   properties: TConversations_GetSourceDeals_Property[];
};
