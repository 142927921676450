import { useInfiniteQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { Database_Table } from "../../database/mutations/utils";

export const useQSOS_FieldsHistory = ({ limit = 10, ...fieldsHistoryQuery }: SoS_Fields_GetFieldsHistory_Query) => {
   const { sosAxios } = useSosAxios();

   return useInfiniteQuery({
      refetchOnMount: true,
      refetchInterval: 1000 * 60,
      queryKey: ["useQSOS_FieldsHistory", fieldsHistoryQuery],
      queryFn: ({ pageParam: offset }) =>
         sosAxios
            .get(`/fields/history`, { params: { limit, ...fieldsHistoryQuery, offset } })
            .then<SoS_Fields_GetFieldsHistory_Response_Pagination>(
               ({ data }: { data: SoS_Fields_GetFieldsHistory_Response_Pagination }) => {
                  return {
                     payload: {
                        audits: data.payload.audits,
                     },
                     metadata: data.metadata,
                  };
               }
            ),
      initialPageParam: 0,
      getNextPageParam: ({ metadata: { nextOffset } }) =>
         nextOffset > 0 && nextOffset >= limit ? nextOffset : undefined,
   });
};

export type SoS_Fields_GetFieldsHistory_Query = {
   table: Database_Table;
   column?: string;
   record_id: string;
   limit?: number;
   offset?: number;
   order?: "ASC" | "DESC";
};

export class SoS_Fields_GetFieldsHistory_Response_Audit {
   daid!: number;
   column!: string;
   created_at!: string;
   old_value!: string;
   new_value!: string;
   user_name!: string | null;
   user_email!: string;
}

export type SoS_Fields_GetFieldsHistory_Response = {
   audits: SoS_Fields_GetFieldsHistory_Response_Audit[];
};

export type SoS_Fields_GetFieldsHistory_Response_Pagination = {
   payload: SoS_Fields_GetFieldsHistory_Response;
   metadata: { totalRows: number; nextOffset: number };
};
