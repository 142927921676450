import { useMutation, useIsMutating } from "@tanstack/react-query";
import { useToastContext } from "../../../contexts/ToastContext";
import { useSosAxios } from "../../axios/useSosAxios";
import { Database_Table, Database_Column } from "./utils";

export const useMDatabase_UploadFiles = (p?: useMDatabase_UploadFiles_Params) => {
   const { table, column, identifiers, onComplete = () => {} } = p || {};
   const { sosAxios } = useSosAxios({ redirectOnError: false });
   const { toastDispatch } = useToastContext();
   const mutationKey = [table, column, identifiers];

   const mutation = useMutation({
      mutationKey,
      onSuccess: () => {
         onComplete(true);
         toastDispatch({ type: "ADD_TOAST", payload: { type: "success", message: "Files Uploaded" } });
      },
      onError: () => {
         onComplete(false);
         toastDispatch({ type: "ADD_TOAST", payload: { type: "error", message: "Invalid Files" } });
      },
      mutationFn: ({ files }: { files: FileList }) => {
         const formData = new FormData();
         //  if (table) formData.append("table", table);
         //  if (column) formData.append("column", column);
         //  if (identifiers) formData.append("identifiers", JSON.stringify(identifiers));
         Array.from(files).forEach((file) => formData.append("files[]", file));

         return sosAxios.post("/database/files", formData, {
            headers: { "Content-Type": "multipart/form-data" },
            params: { table, column, identifiers },
         });
      },
   });
   const isMutating = useIsMutating({ mutationKey });

   return { ...mutation, isMutating };
};

export type useMDatabase_UploadFiles_Params = {
   table: Database_Table;
   column: Database_Column;
   identifiers: { column: string; id: string | number }[];
   onComplete?: (isSuccess: boolean) => void;
};
