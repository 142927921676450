import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useOffersContext } from "../../../../contexts/OffersContext";

export const useMOffers_Leads_UpdateLeadAssignee = ({ onSuccess, onError }: TUseMOffers_Leads_UpdateLeadAssignee) => {
   const { sosAxios } = useSosAxios();

   const { offersState } = useOffersContext();

   const { lid } = offersState;

   return useMutation({
      onSuccess,
      onError,
      mutationFn: ({ uids }: TUpdateLeadAssignneParams) =>
         sosAxios
            .put(`/offers/leads/${lid}/assignee`, { uids })
            .then<TSoS_Offers_UpdateLeadUsers_Response>(({ data }) => data),
   });
};

type TUseMOffers_Leads_UpdateLeadAssignee = {
   onSuccess: (data: TSoS_Offers_UpdateLeadUsers_Response) => void;
   onError: (message: string) => void;
};

type TUpdateLeadAssignneParams = {
   uids: string[];
};

export type TSoS_Offers_UpdateLeadUsers_Response = {
   message: string;
};
