import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";

export const useMTT_CreateTermTemplate = ({ onSuccess, onError }: Partial<TUseMTT_CreateTermTemplateProps>) => {
   const { sosAxios } = useSosAxios();

   const mutation = useMutation({
      onSuccess,
      onError,
      mutationFn: (createTermTemplateProps: TCreateTermTemplateProps) =>
         sosAxios
            .post("/offers/term-templates", { name: createTermTemplateProps.name })
            .then<SoS_CreateTermTemplate_Response>(({ data }) => data),
   });

   return { ...mutation };
};

type TUseMTT_CreateTermTemplateProps = {
   onSuccess: (data: SoS_CreateTermTemplate_Response) => void;
   onError: (message: string) => void;
};

type TCreateTermTemplateProps = {
   name: string;
};

type SoS_CreateTermTemplate_TermTemplate = {
   tid: number;
   name: string;
};

export type SoS_CreateTermTemplate_Response = { template: SoS_CreateTermTemplate_TermTemplate };
