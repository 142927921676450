import React, { createContext, useContext, useEffect, useReducer } from "react";
import { ToastContainer, TToast } from "../components/ToastContainer/ToastContainer";

type ToastState = {
   toasts: TToast[];
};

type ToastAction =
   | { type: "ADD_TOAST"; payload: TToast }
   | { type: "REMOVE_TOAST"; payload: { index: number } }
   | { overwrite: TToastOverwrite };

type TToastOverwrite = Partial<ToastState>;

export const ToastContext = createContext<{ toatsState: ToastState; toastDispatch: React.Dispatch<ToastAction> }>({
   toatsState: { toasts: [] },
   toastDispatch: () => {},
});

const toastReducer = (state: ToastState, action: ToastAction): ToastState => {
   if ("overwrite" in action) {
      return { ...state, ...action.overwrite };
   }
   switch (action.type) {
      case "ADD_TOAST": {
         return { ...state, toasts: [...state.toasts, action.payload] };
      }
      case "REMOVE_TOAST": {
         const newToasts = state.toasts.filter((_, index) => index !== action.payload.index);
         return { ...state, toasts: newToasts };
      }
      default:
         return state;
   }
};

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
   const [toatsState, toastDispatch] = useReducer(toastReducer, { toasts: [] });
   useEffect(() => {
      const timers = toatsState.toasts.map((_, i) =>
         setTimeout(() => toastDispatch({ type: "REMOVE_TOAST", payload: { index: i } }), 3000)
      );
      return () => timers.forEach(clearTimeout);
   }, [toatsState]);

   return (
      <ToastContext.Provider value={{ toatsState, toastDispatch }}>
         {children}
         <ToastContainer />
      </ToastContext.Provider>
   );
};

export const useToastContext = () => {
   const context = useContext(ToastContext);
   const toastOverwrite = (overwrite: TToastOverwrite) => context.toastDispatch({ overwrite });
   return { ...context, toastOverwrite };
};
