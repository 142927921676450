import classNames from "classnames/bind";
import styles from "./SoSRoudedTabs.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
const cx = classNames.bind(styles);

type TSoSRoudedTabsProps = {
   tabs: string[];
   value: string;
   onChange: (value: string) => void;
   isCreated?: boolean;
   onCreate?: () => void;
};

export const SoSRoudedTabs = ({ tabs, value, isCreated, onChange, onCreate }: TSoSRoudedTabsProps) => {
   return (
      <div className={cx("tabsContainer", { padding: isCreated })}>
         {tabs.map((tab, index) => (
            <div
               key={index}
               className={cx("tab", { active: value === tab, primary: isCreated })}
               onClick={() => onChange(tab)}
            >
               {tab}
            </div>
         ))}
         {isCreated && (
            <div className={cx("img-icon")} onClick={onCreate}>
               <img src={CF_CDN_URL("/assets/property_validator/add_black.svg")} alt="" />
            </div>
         )}
      </div>
   );
};
