import classNames from "classnames/bind";
import { SHrPopup, SHrSosButton } from "@simplyhomes/react";

import styles from "./SoSConvoThreadCard.module.scss";
import { SHu_js_timeSince } from "@simplyhomes/utils";
import { useQConvo_Threads } from "../../../hooks/conversation-api/queries/threads/useQConvo_Threads";
import { useSoSOptions_Convo_Thread_Tags } from "../../../hooks/queries/options/useQSoS_Options";
import { useMConvo_ToggleThreadSubscription } from "../../../hooks/conversation-api/mutates/threads/useMConvo_ToggleThreadSubscription";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { handleString, SoSConvoPostCommentModal } from "../SoSConvoPostCommentModal/SoSConvoPostCommentModal";
import { useConvoContext } from "../../../contexts/ConvoContext";

const cx = classNames.bind(styles);

type TPViThreadCardProps = {
   tid: number;
};

export const SoSConvoThreadCard = ({ tid }: TPViThreadCardProps) => {
   const { convoState, convoDispatch } = useConvoContext();

   const { showThreadDetailsModal } = convoState;

   const qGetThreads = useQConvo_Threads();

   const qGetConvoTags = useSoSOptions_Convo_Thread_Tags();

   const getConvoTagOptions = qGetConvoTags.data?.options;

   const { mutate, isPending } = useMConvo_ToggleThreadSubscription();

   if (!qGetThreads.data) return null;

   const { threadsMap } = qGetThreads;

   const thread = threadsMap[tid];

   const { title, content, comment_count, creator, tags, created_at, subscribed } = thread;

   const convoTagsLabel = tags?.map((value) => {
      const option = getConvoTagOptions?.find((o) => o.value === value);
      return option ? option.label : null;
   });

   const handleSubscribe = () => {
      mutate({ tid: thread.tid, subscribed: !subscribed });
   };
   const timestamp = new Date(created_at).getTime();

   return (
      <div className={cx("threadCardC")}>
         <div className={cx("personC")}>
            <div className={cx("avt")}>
               <img src={creator.picture} alt="" />
            </div>
            <div className={cx("nameC")}>
               <span>
                  <b>{creator.name}</b>
               </span>
               <span className={cx("role")}>{creator.roles}</span>
            </div>
            <span className={cx("time")}>{SHu_js_timeSince(timestamp)}</span>
            <div>
               <SHrSosButton
                  type="text"
                  loading={isPending}
                  buttonProps={{ className: cx("btn"), onClick: handleSubscribe }}
               >
                  {subscribed ? "Unsubscribe" : "Subscribe"}
               </SHrSosButton>
            </div>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/property_viability/more_horiz_neutral500.svg")} alt="" />
            </div>
         </div>
         <div
            className={cx("contentC")}
            onClick={() => convoDispatch({ overwrite: { showThreadDetailsModal: true, tid: thread.tid } })}
         >
            <span className={cx("title")}>{title}</span>
            <p>{handleString(content)}</p>

            {convoTagsLabel.length > 0 && (
               <div className={cx("keywordC")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_viability/local_offer_black.svg")} alt="" />
                  </div>

                  <div className={cx("keywordGroupC")}>
                     {convoTagsLabel.map((tag, i) => (
                        <div className={cx("keyword")} key={i}>
                           {tag}
                        </div>
                     ))}
                  </div>
               </div>
            )}
         </div>
         <div className={cx("commentC")}>
            <span className={cx("numberComs")}>
               {comment_count} {comment_count > 1 ? "comments" : "comment"}
            </span>
            <div className={cx("dot")}></div>
            <div
               className={cx("postCom")}
               onClick={() => convoDispatch({ overwrite: { showThreadDetailsModal: true, tid: thread.tid } })}
            >
               Post a comment
            </div>
         </div>

         <SHrPopup show={showThreadDetailsModal}>
            <SoSConvoPostCommentModal />
         </SHrPopup>
      </div>
   );
};
