import classNames from "classnames/bind";
import styles from "./SoSSwich.module.scss";

const cx = classNames.bind(styles);
export const SoSSwich = ({ checked, inputProps, labelProps, onChange }: SoSSwichProps) => {
   return (
      <label {...labelProps} className={cx("switch")}>
         <input {...inputProps} type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} />
         <span className={cx("slider")}></span>
      </label>
   );
};
type SoSSwichProps = {
   checked: boolean;
   onChange: (b: boolean) => void;
   labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
   inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
};
