import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "./../../../axios/useSosAxios";

export const useMOffers_Leads_CreateLead = ({ onSuccess, onError }: TUseMOffers_Leads_CreateLead) => {
   const { sosAxios } = useSosAxios();

   return useMutation({
      onSuccess,
      onError,
      mutationFn: (createLeadProps: TSoS_Offer_CreateLead_Props) =>
         sosAxios.post(`/offers/leads`, createLeadProps).then<TSoS_Offer_CreateLead_Response>(({ data }) => data),
   });
};

type TUseMOffers_Leads_CreateLead = {
   onSuccess: (data: TSoS_Offer_CreateLead_Response) => void;
   onError: (message: string) => void;
};

export type TSoS_Offer_CreateLead_Props = {
   pids: string[];
   sid: number;
};

export type TSoS_Offer_CreateLead_Lead = {
   lid: number;
};

export type TSoS_Offer_CreateLead_Response = { lead: TSoS_Offer_CreateLead_Lead };
