import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { usePViContext } from "../../../contexts/PViContext";
import { useToastContext } from "../../../contexts/ToastContext";
import { useQPVi_Properties } from "../queries/useQPVi_Properties";

export const useMPVi_Source_CreateSource = ({ onSuccess }: TUseMPVi_Source_CreateSource) => {
   const { sosAxios } = useSosAxios();

   const { pviDispatch } = usePViContext();

   const { toastDispatch } = useToastContext();

   const qProperty = useQPVi_Properties();

   const mutation = useMutation({
      onSuccess: (data) => {
         qProperty.refetch();
         toastDispatch({ type: "ADD_TOAST", payload: { message: "Source create sucessfully!", type: "success" } });
         if (data.pids && data.pids.length) pviDispatch({ overwrite: { pid: data.pids[0], showDetailsModal: true } });
         onSuccess(data);
      },
      mutationFn: (p: CreateSourceParams) =>
         sosAxios.post(`/property-viability/properties/sources`, { ...p }).then((res) => res.data),
   });
   return { ...mutation };
};

export type TUseMPVi_Source_CreateSource = {
   onSuccess: (data: CreateSourceResponse) => void;
};

export type CreateSourceParams_Property_Unit = {
   bed: number;
   bath: number;
};

export type CreateSourceParams_Property = {
   address: string;
   price: number;
   cids: number[];
   units: CreateSourceParams_Property_Unit[];
};

export type CreateSourceParams = {
   source_source: string;
   cids: number[];
   properties: CreateSourceParams_Property[];
};

export type CreateSourceResponse = {
   pids: string[];
};
