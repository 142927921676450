import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { usePViContext } from "../../../../contexts/PViContext";
import { useQPVi_Property } from "../../queries/useQPVi_Property";

export const useMPVi_Property_CreateUnit = () => {
   const { sosAxios } = useSosAxios();

   const { pviState } = usePViContext();

   const { pid } = pviState;

   const qPVi_Prop = useQPVi_Property();

   const mutationKey = ["useMPVi_Property_CreateUnit", pid];

   const isMutating = useIsMutating({ mutationKey });

   const mutation = useMutation({
      mutationKey,
      onSuccess: () => qPVi_Prop.refetch(),
      mutationFn: () => sosAxios.post(`/property-viability/properties/${pid}/units`),
   });
   return { ...mutation, isMutating };
};
