import { SHu_js_currencyFormat, SHu_js_percentageFormat } from "@simplyhomes/utils";
import classNames from "classnames/bind";
import styles from "./OffersTermPoint.module.scss";

const cx = classNames.bind(styles);

export const OffersTermPoint = (p: TTermPointProps) => {
   const { title, isNumber, type, value, format, isMocked } = p;

   const formattedValue = isNumber && format ? helperFormatNumber(value as number, format) : value;

   return (
      <div className={cx("contentC", `type-${type}`, { isMocked })}>
         <div className={cx("titleC")}>
            <span>{title}</span>
         </div>
         <div className={cx(`valueC`)}>
            <div className={cx("value")}>
               <span>{formattedValue}</span>
            </div>
         </div>
      </div>
   );
};

type TTermPointProps = {
   title: string;
   isNumber?: boolean;
   type: "bold-value" | "bold-title" | "only-value";
   value: string | undefined | null | number;
   format?: "currency" | "percentage";
   isMocked?: boolean;
};

const helperFormatNumber = (value: number, format: "currency" | "percentage") => {
   if (format === "currency") return SHu_js_currencyFormat(value);

   if (format === "percentage") return SHu_js_percentageFormat(value);

   return "N/A";
};
